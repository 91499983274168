import React, { Component } from "react";
import PropTypes from "prop-types";

import Icon from "./Icon";

export default class Alert extends Component {
  static propTypes = {
    /**
     * The content of the alert. When a string is passed it is wrapped in a `<Text size={400} />` component.
     */
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

    /**
     * The intent of the alert.
     */
    intent: PropTypes.oneOf([
      "info",
      "success",
      "warning",
      "error",
      "processing",
    ]).isRequired,

    /**
     * The title of the alert.
     */
    title: PropTypes.node,

    /**
     * When true, show a icon on the left matching the type,
     */
    hasIcon: PropTypes.bool,

    /**
     * When true, show a remove icon button.
     */
    isRemoveable: PropTypes.bool,

    /**
     * Function called when the remove button is clicked.
     */
    onRemove: PropTypes.func,
  };

  static defaultProps = {
    intent: "info",
    hasIcon: true,
    isRemoveable: false,
  };

  getIconForIntent = (intent) => {
    switch (intent) {
      case "info":
        return <Icon icon={["far", "info-circle"]} className="text-blue-500" />;
      case "success":
        return (
          <Icon icon={["far", "check-circle"]} className="text-green-500" />
        );
      case "warning":
        return (
          <Icon
            icon={["far", "exclamation-triangle"]}
            className="text-orange-500"
          />
        );
      case "error":
        return (
          <Icon icon={["far", "exclamation-circle"]} className="text-red-500" />
        );
      default:
        return "";
    }
  };

  render() {
    const {
      title,
      intent,
      hasIcon,
      children,
      isRemoveable,
      onRemove,
      className,
    } = this.props;

    let borderLeft = "border-b-4 ";
    switch (intent) {
      case "info":
        borderLeft += "border-blue-500";
        break;
      case "success":
        borderLeft += "border-green-500";
        break;
      case "warning":
        borderLeft += "border-orange-500";
        break;
      case "error":
        borderLeft += "border-red-500";
        break;
      default:
        borderLeft += "border-gray-300";
    }

    return (
      <div className={""}>
        <div
          className={
            "flex flex-row items-start flex-shrink-0 py-3 px-4 rounded shadow-lg pointer-events-auto bg-white " +
            borderLeft +
            " " +
            className
          }
        >
          {hasIcon && (
            <div className="mr-2 flex mt-px p-px">
              {this.getIconForIntent(intent)}
            </div>
          )}

          <div className="flex flex-col w-full">
            <div className="flex-1">
              <p className="text-sm text-gray-700 leading-tight">{title}</p>
            </div>
            {typeof children === "string" ? (
              <div className="flex-1 mt-1">
                <p className="text-sm text-gray-500 leading-tight">
                  {children}
                </p>
              </div>
            ) : (
              children
            )}
          </div>

          {isRemoveable && (
            <button
              className="relative flex-shrink-0 ml-4 w-6 h-6 rounded bg-gray-100 hover:bg-gray-200 leading-none focus:outline-none focus:shadow-outline"
              type="button"
              onClick={onRemove}
            >
              <Icon icon={["far", "times"]} className="text-gray-600" />
            </button>
          )}
        </div>
      </div>
    );
  }
}
