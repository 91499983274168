import React from "react";

import Icon from "./Icon";

const Offline = () => {
  return (
    <>
      <Icon
        icon={["far", "wifi-slash"]}
        size="4x"
        className="text-red-500 mb-8"
      />
      <h2 className="text-gray-900 mb-2 font-bold text-xl">
        Connect to the Internet
      </h2>
      <p>You're offline. Check your connection.</p>
    </>
  );
};

export default Offline;
