const initialState = {
  isUpdating: false,
  isLoading: false,
  fields: [],
  loads: [],
  clears: [],
  currentYard: [],
};

const yard = (state = initialState, { type, payload }) => {
  switch (type) {
    case "getAppDataCommit":
    case "loginUserCommit":
      return {
        ...state,
        fields: payload.yard_fields,
      };
    case "getLoadRequestsInit":
      return {
        ...state,
        isLoading: true,
      };

    case "getLoadRequestsCommit":
      return {
        ...state,
        loads: payload.data.loads,
        clears: payload.data.clears,
        isLoading: false,
      };

    case "getLoadRequestsFail":
      return {
        ...state,
        isLoading: false,
      };

    case "getYardLoadInit":
      return {
        ...state,
        isLoading: true,
      };

    case "getYardLoadCommit":
      return {
        ...state,
        currentYard: payload.data.yard,
        isLoading: false,
      };

    case "getYardLoadFail":
      return {
        ...state,
        currentYard: [],
        isLoading: false,
      };

    case "createYardLoadInit":
    case "updateYardLoadInit":
      console.log("updateYardLoadInit", payload.id, payload.status);
      if (payload.status === "load_declined") {
        console.log("updateYardLoadInit", payload);

        return {
          ...state,
          currentYard: state.currentYard.filter((load) => {
            return load.id !== payload.id;
          }),
          isUpdating: true,
        };
      }

      return {
        ...state,
        isUpdating: true,
      };

    case "createYardLoadCommit":
      let newArray = state.currentYard.slice();
      newArray.splice(state.currentYard.length, 0, payload.data.yard);

      return {
        ...state,
        currentYard: newArray,
        isUpdating: false,
      };

    case "updateYardLoadCommit":
      // build a new array...
      let newYard = state.currentYard.slice();

      if (payload.data.yard.status === "load_declined") {
        newYard = newYard.filter((load) => {
          return load.id !== payload.data.yard.id;
        });
      }

      // resumitted a declined request - we need to remove old one and append new one.
      if (payload.data.deleted_id) {
        newYard = newYard.filter((load) => {
          return load.id !== payload.data.deleted_id;
        });
        newYard.splice(state.currentYard.length, 0, payload.data.yard);
      }

      // otherwise lets update exiting items...
      newYard = newYard.map((load) => {
        if (load.id === payload.data.yard.id) {
          return payload.data.yard;
        }
        return load;
      });

      return {
        ...state,
        currentYard: newYard,
        // currentYard: concatAndDeDuplicateObjects(
        //   "id",
        //   [payload.data.yard],
        //   state.currentYard
        // ),
        loads: state.loads.filter((load) => {
          if (
            load.id === payload.data.yard.id &&
            payload.data.yard.status !== "load_requested"
          ) {
            return false;
          }
          return true;
        }),
        clears: state.clears.filter((load) => {
          if (
            load.id === payload.data.yard.id &&
            payload.data.yard.status !== "clear_requested"
          ) {
            return false;
          }
          return true;
        }),
        isUpdating: false,
      };

    case "updateYardLoadFail":
    case "createYardLoadFail":
      return {
        ...state,
        isUpdating: false,
      };

    default:
      return state;
  }
};
export default yard;
