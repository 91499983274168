const initialState = {
  isLoading: true,
  rates: [],
};

const ratecard = (state = initialState, { type, payload }) => {
  switch (type) {
    case "getRateCardInit":
      return {
        ...initialState,
      };
    case "getRateCardFail":
      return {
        ...initialState,
        isLoading: false,
      };
    case "getRateCardCommit":
      return {
        ...payload.data.ratecard,
        isLoading: false,
      };
    case "showHideRate":
      console.warn("showHideRate", payload);
      return {
        ...state,
        rates: state.rates.map((rate) => {
          if (rate.id === payload.id) {
            console.log("rate found", payload.open);
            return payload;
          }
          return rate;
        }),
      };
    default:
      return state;
  }
};
export default ratecard;
