import axios from "axios";
import { API_URL, API_CONFIG } from "../constants";
import { handleError, successMessage } from "../utils/serverMessages";

const getManagersInit = (payload, meta) => {
  return {
    type: "getManagersInit",
    payload: payload,
    meta: meta,
  };
};
export const getManagersCommit = (payload, meta) => {
  return {
    type: "getManagersCommit",
    payload: payload,
    meta: meta,
  };
};
const getManagersFail = (payload, meta) => {
  return {
    type: "getManagersFail",
    payload: payload,
    meta: meta,
  };
};

export const getManagers = (payload, meta) => {
  return (dispatch) => {
    let url = API_URL + "/managers";
    if (payload.includeArchived) {
      url += "?include_archived=" + payload.includeArchived;
    }
    dispatch(getManagersInit(payload, meta));
    axios
      .get(url, API_CONFIG)
      .then((response) => {
        successMessage(response);
        dispatch(getManagersCommit(response.data, meta));
      })
      .catch((error) => {
        handleError(error, false, dispatch);
        dispatch(getManagersFail(payload, meta));
      });
  };
};
