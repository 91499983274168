import React, { Component } from "react";
import { connect } from "react-redux";

import { Formik, Form, Field } from "formik";

import Dialog from "../../ui/Dialog";
import Loading from "../../ui/Loading";

import Button from "../../ui/Button";
import Divider from "../../ui/Divider";

import { getManagers } from "../../actions/managers";

class ManagerPicker extends Component {
  render() {
    const {
      isShown,
      user,
      onCloseComplete,
      updateManager,
      managers,
      selected,
      disableButtons,
      multiple,
      includeArchived,
      isRequired,
    } = this.props;
    return (
      <Dialog
        title={multiple ? "Select Area Supervisors" : "Select Area Supervisor"}
        isShown={isShown}
        onCloseComplete={onCloseComplete}
        onOpen={() => {
          // fetch all managers from server
          this.props.getManagers({
            includeArchived: includeArchived,
          });
        }}
        disableButtons={disableButtons}
        hideFooter
        fullHeight
        narrow
      >
        {({ close }) => (
          <Formik
            initialValues={{
              manager: multiple
                ? selected
                  ? selected
                  : []
                : selected
                ? "ID" + selected
                : "",
            }}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={(values) => {
              updateManager(
                values,
                multiple
                  ? managers.data.filter((manager) =>
                      values.manager.includes("ID" + manager.id)
                    )
                  : managers.data.find(
                      (manager) => values.manager === "ID" + manager.id
                    ),
                close
              );
            }}
          >
            {({ errors, touched, isSubmitting, values }) => (
              <Form className="w-full relative flex flex-col items-stretch">
                <Divider />
                {managers.isLoading && (
                  <div className="z-10 absolute inset-0 bg-white bg-opacity-75">
                    <Loading />
                  </div>
                )}
                <div className="relative w-full h-full flex flex-col items-stretch overflow-auto">
                  {!isRequired && (
                    <label className="p-2 border border-gray-200 rounded mb-1">
                      <Field
                        name="manager"
                        type={"radio"}
                        value=""
                        className="mr-2"
                      />
                      No Area Supervisor
                    </label>
                  )}
                  {isRequired &&
                    !managers.isLoading &&
                    managers.data.length <= 0 && (
                      <div className="p-2 border border-gray-200 rounded mb-1">
                        No Area Supervisor Found
                      </div>
                    )}
                  {managers.data.map((manager) => {
                    return (
                      <label
                        key={manager.id}
                        className={
                          "p-2 border border-gray-200 rounded mb-1" +
                          (manager.deleted_at !== null
                            ? " text-gray-500 line-through"
                            : "")
                        }
                      >
                        <Field
                          name="manager"
                          type={multiple ? "checkbox" : "radio"}
                          value={"ID" + manager.id}
                          className="mr-2"
                        />
                        {manager.display_name}
                      </label>
                    );
                  })}
                </div>

                <Divider />
                <div className="">
                  {user && user.isUpdating && (
                    <Button fullWidth appearance="dark" disabled type="button">
                      Updating...
                    </Button>
                  )}

                  {(!user || !user.isUpdating) && (
                    <Button fullWidth appearance="dark" type="submit">
                      {multiple ? "Update Supervisors" : "Update Supervisor"}
                    </Button>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        )}
      </Dialog>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    user: store.appState.user,
    managers: store.managers,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getManagers: (payload, meta) => dispatch(getManagers(payload, meta)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManagerPicker);
