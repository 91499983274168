import React, { Component, Suspense, lazy } from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";

import Media from "react-media";
import { Breakpoints } from "../constants";

import Loading from "../ui/Loading";
import RotateDevice from "../ui/RotateDevice";
import DeviceTooSmall from "../ui/DeviceTooSmall";

import NavigationDesktop from "../ui/NavigationDesktop";
import NavigationTablet from "../ui/NavigationTablet";
import NavigationMobile from "../ui/NavigationMobile";

const Dashboard = lazy(() => import("./Dashboard"));
const Timesheets = lazy(() => import("./Timesheets"));
const TimesheetsDesktop = lazy(() => import("./TimesheetsDesktop"));
const TimesheetApprovals = lazy(() => import("./TimesheetApprovals"));
const TimesheetApprovalsDesktop = lazy(() =>
  import("./TimesheetApprovalsDesktop")
);
const HealthSafety = lazy(() => import("./HealthSafety"));
const Settings = lazy(() => import("./Settings"));
const Users = lazy(() => import("./Users"));
const UsersDesktop = lazy(() => import("./UsersDesktop"));
const Developments = lazy(() => import("./Developments"));
const DevelopmentsDesktop = lazy(() => import("./DevelopmentsDesktop"));
const RatecardsDesktop = lazy(() => import("./RatecardsDesktop"));
const YardManagersDesktop = lazy(() => import("./YardManagersDesktop"));

class Routes extends Component {
  state = {
    orientation: window.orientation,
    isMobile:
      window.screen.width <= 767 ||
      window.orientation === 90 ||
      window.orientation === -90,
    isDesktop: window.screen.width >= 1024,
  };
  setOrientation = () => {
    this.setState({
      orientation: window.orientation,
      isMobile:
        window.screen.width <= 767 ||
        window.orientation === 90 ||
        window.orientation === -90,
    });
  };
  componentDidMount() {
    window.addEventListener("orientationchange", this.setOrientation, false);
  }
  componentWillUnmount() {
    window.removeEventListener("orientationchange", this.setOrientation, false);
  }
  render() {
    const { user, timesheet_week, to_approve_count } = this.props;
    console.log("state", this.state);

    if (user && user.role === "yard-manager") {
      return (
        <Media queries={Breakpoints}>
          {(matches) => (
            <>
              {(!matches.lg || !matches.height) && (
                <DeviceTooSmall matches={matches} />
              )}
              <Router>
                <Suspense fallback={<Loading />}>
                  <Route exact path="/" component={YardManagersDesktop} />
                </Suspense>
              </Router>
            </>
          )}
        </Media>
      );
    }
    return (
      <>
        {this.state.isMobile &&
          !this.state.isDesktop &&
          (this.state.orientation === 90 || this.state.orientation === -90) && (
            <RotateDevice />
          )}
        <Router>
          <div id="main-view">
            <div id="main-view-inner" className="bottom-minus-nav md:bottom-0">
              <div className="content safe-pt safe-pb">
                <Suspense fallback={<Loading />}>
                  <Switch>
                    <Route exact path="/" component={Dashboard} />
                    <Route
                      exact
                      path="/health-safety"
                      component={HealthSafety}
                    />

                    <Route exact path="/settings" component={Settings} />

                    {user && user.role === "operative" && (
                      <Route path="/timesheets">
                        <Switch>
                          <Route
                            path="/timesheets"
                            exact
                            component={
                              !this.state.isMobile
                                ? TimesheetsDesktop
                                : Timesheets
                            }
                          />
                          <Route
                            path="/timesheets/:date"
                            exact
                            component={
                              !this.state.isMobile
                                ? TimesheetsDesktop
                                : Timesheets
                            }
                          />
                          <Route
                            path="/timesheets/:date/:item"
                            exact
                            component={
                              !this.state.isMobile
                                ? TimesheetsDesktop
                                : Timesheets
                            }
                          />
                        </Switch>
                      </Route>
                    )}
                    {user && user.role !== "operative" && (
                      <Route
                        path="/timesheet-approvals"
                        exact
                        component={
                          !this.state.isMobile
                            ? TimesheetApprovalsDesktop
                            : TimesheetApprovals
                        }
                      />
                    )}
                    {user &&
                      (user.role === "administrator" ||
                        user.role === "superuser") && (
                        <Route
                          path="/users"
                          exact
                          component={
                            !this.state.isMobile ? UsersDesktop : Users
                          }
                        />
                      )}
                    {user &&
                      (user.role === "administrator" ||
                        user.role === "superuser" ||
                        user.role === "supervisor" ||
                        (user.role === "operative" &&
                          user.permissions &&
                          user.permissions.request_loads)) && (
                        <Route
                          path="/developments"
                          exact
                          component={
                            !this.state.isMobile
                              ? DevelopmentsDesktop
                              : Developments
                          }
                        />
                      )}
                    {user &&
                      (user.role === "administrator" ||
                        user.role === "superuser") && (
                        <Route
                          path="/rate-cards"
                          exact
                          component={
                            !this.state.isMobile ? RatecardsDesktop : Users
                          }
                        />
                      )}
                  </Switch>
                </Suspense>
              </div>
            </div>
          </div>

          <Media queries={Breakpoints}>
            {(matches) =>
              matches.lg ? (
                <NavigationDesktop
                  timesheet_week={timesheet_week}
                  to_approve_count={to_approve_count}
                  user={user}
                />
              ) : !this.state.isMobile || matches.md ? (
                <>
                  {(!matches.md || !matches.height) && (
                    <DeviceTooSmall matches={matches} />
                  )}
                  <NavigationTablet
                    to_approve_count={to_approve_count}
                    timesheet_week={timesheet_week}
                    user={user}
                  />
                </>
              ) : (
                <NavigationMobile
                  to_approve_count={to_approve_count}
                  timesheet_week={timesheet_week}
                  user={user}
                />
              )
            }
          </Media>
        </Router>
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    to_approve_count: store.documents.to_approve.length,
    user: store.appState.user,
    timesheet_week: store.entries.week,
    currentAccount:
      store.appState.user && store.appState.user.account_view
        ? store.appState.user.account_view
        : false,
  };
};

export default connect(mapStateToProps, null)(Routes);
