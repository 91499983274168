import React from "react";

const DeviceTooSmall = ({ matches }) => {
  return (
    <div className="fixed inset-0 z-150 bg-white flex flex-col w-full h-full items-center justify-center">
      <svg className="text-red-500 mb-8 w-40" viewBox="0 0 640 640">
        <g
          id="device-too-small"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Group-2"
            transform="translate(15.479495, 87.000000)"
            fill="currentColor"
            fillRule="nonzero"
          >
            <path
              d="M429.320428,469 C569.266682,469 309.811668,469 589.704177,469 C602.305287,469 612.520505,458.789594 612.520505,446.194419 L612.520505,22.8055807 C612.520505,10.2104063 602.305287,0 589.704177,0 L22.3368326,0 C9.73572266,0 -0.479495268,10.2104063 -0.479495268,22.8055807 C-0.479495268,119.354243 -0.479495268,135.898855 -0.479495268,184.173186 L12.5584064,184.173186 C12.5584064,136.354951 12.5584064,118.44205 12.5584064,22.8055807 C12.5584064,17.4076488 16.9363569,13.0317604 22.3368326,13.0317604 L589.704177,13.0317604 C595.104653,13.0317604 599.482603,17.4076488 599.482603,22.8055807 L599.482603,446.194419 C599.482603,451.592351 595.104653,455.96824 589.704177,455.96824 C310.212684,455.96824 569.066174,455.96824 429.320428,455.96824 L429.320428,469 Z"
              id="Path"
            ></path>
          </g>
          <g
            id="Group"
            transform="translate(14.000000, 300.047319)"
            fill="currentColor"
            fillRule="nonzero"
          >
            <path
              d="M382.223338,-0.047318612 L22.7766619,-0.047318612 C10.1974589,-0.047318612 0,9.62949918 0,21.5664589 L0,234.338904 C0,246.275864 10.1974589,255.952681 22.7766619,255.952681 L382.223338,255.952681 C394.802541,255.952681 405,246.275864 405,234.338904 L405,21.5664589 C405,9.62949918 394.802541,-0.047318612 382.223338,-0.047318612 Z M22.7766619,12.3034114 L382.223338,12.3034114 C387.614425,12.3034114 391.984765,16.450619 391.984765,21.5664589 L391.984765,234.338904 C391.984765,239.454744 387.614425,243.601951 382.223338,243.601951 L22.7766619,243.601951 C17.3855749,243.601951 13.0152354,239.454744 13.0152354,234.338904 L13.0152354,21.5664589 C13.0152354,16.450619 17.3855749,12.3034114 22.7766619,12.3034114 Z"
              id="Rectangle"
            ></path>
          </g>
          <path
            d="M555.466798,149.138062 L491.1086,149.138062 C487.007621,149.138062 483.682654,152.463029 483.682654,156.564008 L483.682654,161.514638 C483.682654,165.615617 487.007621,168.940584 491.1086,168.940584 L529.087982,168.940584 L436.35153,261.677036 C433.451698,264.576868 433.451698,269.278729 436.35153,272.17918 L439.851626,275.679276 C442.751458,278.579108 447.453319,278.579108 450.35377,275.679276 L543.090222,182.942824 L543.090222,220.922206 C543.090222,225.023185 546.415189,228.348152 550.516168,228.348152 L555.466798,228.348152 C559.567777,228.348152 562.892744,225.023185 562.892744,220.922206 L562.892744,156.564008 C562.892744,152.463029 559.567777,149.138062 555.466798,149.138062 Z"
            id="Path"
            fill="currentColor"
          ></path>
        </g>
      </svg>

      <h2 className="text-gray-900 mb-2 font-bold text-xl">
        Your screen is too small.
      </h2>
      <p>Please enlarge or work fullscreen to maximize your screen size</p>
    </div>
  );
};

export default DeviceTooSmall;
