const Divider = ({
  margin = "my-4",
  color = "border-gray-200",
  className = "h-0 w-full border-t ",
  ...rest
}) => {
  return (
    <div className={className + " " + margin + " " + color} {...rest}></div>
  );
};

export default Divider;
