const InputError = ({
  className = "mt-1 text-rose-600 block text-xs",
  children,
  ...rest
}) => {
  return (
    <p className={className} {...rest}>
      {children}
    </p>
  );
};

export default InputError;
