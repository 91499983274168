import React, { Component } from "react";

import Icon from "./Icon";
import Popover from "./Popover";
import Dialog from "./Dialog";
import { DateTime, Interval } from "luxon";

import Media from "react-media";
import { Breakpoints } from "../constants";

class InputDatePicker extends Component {
  state = {
    date: false,
    openSheet: false,
    today: DateTime.utc().startOf("day"),
    currentDate: DateTime.utc().startOf("day"),
    startDate: DateTime.utc().startOf("month").startOf("week").startOf("day"),
    endDate: DateTime.utc().startOf("month").endOf("week").startOf("day").plus({ days: 1 }),
  };

  constructor(props) {
    super(props);
    this.btnRef = React.createRef();
  }

  toggle = () => {
    if (this.state.openSheet) {
      this.close();
    } else {
      this.open();
    }
  };

  open = () => {
    if (this.state.openSheet) {
      return;
    }

    this.setState({ openSheet: true });
  };

  close = () => {
    if (!this.state.openSheet) {
      return;
    }

    this.setState({ openSheet: false });
  };

  openDialog = (open) => {
    const { value } = this.props;

    const date = value
      ? DateTime.fromSQL(value, { zone: "utc" }).startOf("day")
      : DateTime.utc();
    this.setState(
      {
        today: DateTime.utc().startOf("day"),
        currentDate: date,
        startDate: date.startOf("month").startOf("week").startOf("day"),
        endDate: date.endOf("month").endOf("week").startOf("day").plus({ days: 1 }),
      },
      () => {
        open();
      }
    );
  };

  updateMonth = (date) => {
    this.setState({
      currentDate: date,
      startDate: date.startOf("month").startOf("week").startOf("day"),
      endDate: date.endOf("month").endOf("week").startOf("day").plus({ days: 1 }),
    });
  };

  onSelect = (value, close) => {
    console.log("datepicker", value);
    const { field, setFieldValue, onSetValue } = this.props;
    setFieldValue(field, value.toUTC().toFormat("yyyy-MM-dd"));
    if (onSetValue) {
      onSetValue(
        {
          [field]: value.toUTC().toFormat("yyyy-MM-dd"),
        },
        close
      );
    } else {
      close();
    }
  };

  clearDate = (close) => {
    const { field, setFieldValue, onSetValue } = this.props;
    close();
    setFieldValue(field, "");
    if (onSetValue) {
      onSetValue(
        {
          [field]: "",
        },
        close
      );
    } else {
      close();
    }
  };

  renderCalendar = ({ close }) => {
    const { value } = this.props;
    const fieldDate = value ? DateTime.fromSQL(value, { zone: "utc" }) : false;

    return (
      <>
        <div className="flex w-full flex-row items-center justify-center">
          <div className="">
            <button
              type="button"
              className={
                "py-2 px-4 text-center rounded-sm text-gray-900 hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-50 focus:outline-none focus:shadow-outline"
              }
              onClick={() =>
                this.updateMonth(
                  this.state.currentDate.minus({
                    months: 1,
                  })
                )
              }
            >
              <Icon icon={["far", "long-arrow-left"]} />
            </button>
          </div>
          <div className="flex-1 p-2 text-center">
            {this.state.currentDate.toFormat("d MMMM yyyy")}
          </div>
          <div className="">
            <button
              type="button"
              className={
                "py-2 px-4 text-center rounded-sm text-gray-900 hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-50 focus:outline-none focus:shadow-outline"
              }
              onClick={() =>
                this.updateMonth(
                  this.state.currentDate.plus({
                    months: 1,
                  })
                )
              }
            >
              <Icon icon={["far", "long-arrow-right"]} />
            </button>
          </div>
        </div>

        <div className="grid grid-cols-7 gap-1">
          <div className="w-full py-2 text-center text-xs uppercase ">
            {this.state.startDate.weekdayShort}
          </div>
          <div className="w-full py-2 text-center text-xs uppercase ">
            {this.state.startDate.plus({ days: 1 }).weekdayShort}
          </div>
          <div className="w-full py-2 text-center text-xs uppercase ">
            {this.state.startDate.plus({ days: 2 }).weekdayShort}
          </div>
          <div className="w-full py-2 text-center text-xs uppercase ">
            {this.state.startDate.plus({ days: 3 }).weekdayShort}
          </div>
          <div className="w-full py-2 text-center text-xs uppercase ">
            {this.state.startDate.plus({ days: 4 }).weekdayShort}
          </div>
          <div className="w-full py-2 text-center text-xs uppercase ">
            {this.state.startDate.plus({ days: 5 }).weekdayShort}
          </div>
          <div className="w-full py-2 text-center text-xs uppercase ">
            {this.state.startDate.plus({ days: 6 }).weekdayShort}
          </div>
        </div>
        <div className="grid grid-cols-7 gap-1">
          {Interval.fromDateTimes(this.state.startDate, this.state.endDate)
            .splitBy({ days: 1 })
            .map((d) => {
              let classes =
                "w-full py-2 text-center rounded-sm focus:bg-gray-50 focus:outline-none focus:shadow-outline ";
              // active date
              if (
                fieldDate &&
                d.start.hasSame(fieldDate, "day") &&
                d.start.hasSame(fieldDate, "month")
              ) {
                classes += "bg-gray-900 text-white ";
              }
              // if today
              else if (
                d.start.hasSame(this.state.today, "day") &&
                d.start.hasSame(this.state.today, "month")
              ) {
                classes +=
                  "bg-gray-200 text-gray-900 hover:bg-gray-100 hover:text-gray-900 ";
              } else if (d.start.hasSame(this.state.currentDate, "month")) {
                classes +=
                  "text-gray-900 hover:bg-gray-100 hover:text-gray-900 ";
              } else {
                classes +=
                  " text-gray-400 hover:bg-gray-100 hover:text-gray-400 ";
              }

              return (
                <button
                  type="button"
                  className={classes}
                  key={d.start.toSQL()}
                  onClick={() => this.onSelect(d.start, close)}
                >
                  {d.start.toFormat("d")}
                </button>
              );
            })}
        </div>
      </>
    );
  };

  renderButtonContent = (ref, toggle, open, close) => {
    const {
      value,
      style,
      placeholder,
      icon,
      hasError,
      disableClose,
      disabled,
    } = this.props;

    const fieldDate = value ? DateTime.fromSQL(value, { zone: "utc" }) : false;

    const inputClassName =
      (icon ? "pl-12 " : "pl-4 ") +
      (hasError
        ? "border-red-200 bg-rose-50 focus:ring-rose-300 focus:border-rose-300 text-rose-600 "
        : "border-gray-200 bg-gray-50 focus:ring-gray-300 focus:border-gray-300 ") +
      "text-sm w-full appearance-none rounded focus:outline-none focus:ring-1 ";
    const textClasses = !fieldDate ? " text-gray-500" : "";

    return (
      <div ref={ref} className="flex flex-row w-full items-center relative">
        <button
          type="button"
          onClick={() => this.openDialog(toggle)}
          disabled={disabled}
          style={style}
          className={
            "text-left border pr-4 py-2 " + inputClassName + textClasses
          }
        >
          {fieldDate ? fieldDate.toFormat("d MMMM yyyy") : placeholder}
        </button>

        {!disableClose && fieldDate && !disabled && (
          <button
            type="button"
            className={
              "absolute right-0 top-0 h-full text-gray-400 hover:text-gray-700 focus:outline-none px-3"
            }
            onClick={() => this.clearDate(close)}
          >
            <Icon icon={["far", "times"]} />
          </button>
        )}
      </div>
    );
  };

  render() {
    return (
      <Media queries={Breakpoints}>
        {(matches) =>
          matches.sm ? (
            <>
              {this.renderButtonContent(
                this.btnRef,
                this.toggle,
                this.open,
                this.close
              )}

              <Dialog
                {...this.props}
                isShown={this.state.openSheet}
                title={this.props.title ? this.props.title : "Entry Date"}
                hideFooter
                onCloseComplete={() => this.setState({ openSheet: false })}
              >
                <div
                  className={
                    "flex-1 flex flex-col py-2 mx-1 border-t border-gray-200"
                  }
                >
                  <div className={"relative p-1 h-80"}>
                    {this.renderCalendar({
                      close: this.close,
                      sm: matches.sm,
                    })}
                  </div>
                </div>
              </Dialog>
            </>
          ) : (
            <Popover
              placement="bottom-start"
              btnContent={(ref, toggle, open, close) => {
                return this.renderButtonContent(ref, toggle, open, close);
              }}
              content={(props) => {
                return (
                  <div className="relative mb-2 p-2 w-80 rounded bg-white border-gray-200 border shadow-xl flex flex-col">
                    {this.renderCalendar(props)}
                  </div>
                );
              }}
            />
          )
        }
      </Media>
    );
  }
}

export default InputDatePicker;
