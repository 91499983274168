import Icon from "./Icon";

const Button = ({
  type = "button",
  margin = "",
  fullWidth = false,
  appearance = "",
  icon = false,
  children,
  className = "py-2 px-4 rounded-sm focus:outline-none focus:shadow-outline disabled:opacity-25",
  ...rest
}) => {
  let finalClassName = "";
  switch (appearance) {
    case "dark":
      finalClassName =
        className +
        " bg-gray-900 font-bold text-white hover:bg-gray-700 focus:bg-gray-500";
      break;
    case "red":
      finalClassName =
        className +
        " bg-gray-200 text-rose-600 hover:text-rose-800 focus:text-rose-700 ";
      break;
    case "green":
      finalClassName =
        className +
        " bg-gray-200 text-green-600 hover:text-green-800 focus:text-green-700 ";
      break;
    case "pending":
      finalClassName =
        className +
        " bg-white text-green-600 border-t-2 border-gray-200 hover:text-green-800 focus:text-green-700 ";
      break;
    case "danger":
      finalClassName =
        className +
        " bg-rose-600 text-white hover:bg-rose-800 focus:bg-rose-700 ";
      break;
    case "confirm":
      finalClassName =
        className +
        " bg-amber-500 text-white hover:bg-amber-800 focus:bg-amber-700 ";
      break;
    case "success":
      finalClassName =
        className +
        " bg-green-600 text-white hover:bg-green-800 focus:bg-green-700 ";
      break;
    case "light":
      finalClassName =
        className +
        " bg-gray-200 text-gray-900 hover:text-gray-700 focus:text-gray-500 ";
      break;
    default:
      finalClassName =
        className +
        " bg-white text-gray-900 hover:text-gray-700 focus:text-gray-500 ";
  }
  return (
    <button
      type={type}
      className={
        finalClassName + " " + margin + " " + (fullWidth ? "w-full" : "")
      }
      {...rest}
    >
      {icon && <Icon icon={["far", icon]} className={"mr-2"} fixedWidth />}
      {children}
    </button>
  );
};

export default Button;
