import { Field } from "formik";
import Icon from "./Icon";

const Input = ({
  type = "text",
  icon = false,
  placeholder = "",
  hasError = false,
  className = (icon ? "pl-12 " : "pl-4 ") +
    (hasError
      ? "border-red-200 bg-red-50 focus:ring-red-300 focus:border-red-300  placeholder-red-500 "
      : "border-gray-200 bg-gray-50 focus:ring-gray-300 focus:border-gray-300  placeholder-gray-500 ") +
    " block text-sm w-full appearance-none rounded focus:outline-none focus:ring-1 placeholder-gray-500",
  ...rest
}) => {
  return (
    <div className="relative">
      {icon && (
        <Icon
          icon={["far", icon]}
          className={
            "absolute h-full  ml-4 " +
            (hasError ? "text-red-300" : "text-gray-300")
          }
          fixedWidth
        />
      )}
      <Field
        type={type}
        className={className}
        placeholder={placeholder}
        {...rest}
      />
    </div>
  );
};

export default Input;
