import React, { Component } from "react";
import { connect } from "react-redux";
import WebFont from "webfontloader";

import Offline from "../ui/Offline";
import Loading from "../ui/Loading";

import IconLibrary from "../utils/IconLibrary";

import Login from "./Login";
import Routes from "./Routes";

import { getAppData, logoutUser } from "../actions/appState";

const isBrowser = typeof navigator !== "undefined";

class App extends Component {
  state = {
    online:
      isBrowser && typeof navigator.onLine === "boolean"
        ? navigator.onLine
        : true,
    isReady: false,
    fontsLoaded: false,
  };
  constructor() {
    super();
    IconLibrary();
  }
  componentDidMount() {
    window.addEventListener("online", this.wentOnline);
    window.addEventListener("offline", this.wentOffline);

    const _self = this;
    WebFont.load({
      custom: {
        families: ["Inter:n5,n7"],
        urls: ["/webfonts/inter.css"],
      },
      loading: function () {
        console.log("WebFont loading");
      },
      inactive: function () {
        _self.setState({ fontsLoaded: true }); // fonts loaded but failed - prob slow connection???
        console.log("WebFont inactive");
      },
      active: function () {
        _self.setState({ fontsLoaded: true });
        console.log("WebFont Ready");
      },
    });

    // fetchAppData
    this.props.getAppData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.account_view !== this.props.account_view) {
      // update styles...
      const styles = document.getElementById("custom-styles");
      if (styles) {
        document.head.removeChild(styles);
      }
      if (this.props.account_view) {
        const $style = document.createElement("style");
        document.head.appendChild($style);
        $style.id = "custom-styles";
        if (this.props.account_view === "head-office") {
          $style.innerHTML = `:root {
              --primary: #de3d41;
              --primary-hover: #bc3337;
              --primary-active: #9a2a2d;
          }`;
        } else {
          $style.innerHTML = `:root {
            --primary: #0F172A;
            --primary-hover: #212C3F;
            --primary-active: #334155;
          }`;
        }
      }
    }
  }
  componentWillUnmount() {
    window.removeEventListener("online", this.wentOnline);
    window.removeEventListener("offline", this.wentOffline);
  }

  wentOnline = () => {
    this.setState({ online: true });
  };

  wentOffline = () => {
    this.setState({ online: false });
  };

  render() {
    const { isInitialising, hasValidSession } = this.props;

    if (!this.state.fontsLoaded || isInitialising) {
      return <Loading />;
    }

    return (
      <div
        className={
          "relative bg-gray-50 h-screen " +
          (this.state.online && !hasValidSession
            ? "filter blur pointer-events-none"
            : "")
        }
      >
        {!this.state.online && <Offline />}
        {this.state.online && (
          <>
            <Login />
            <Routes />
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    isInitialising: store.appState.isInitialising,
    hasValidSession: store.appState.hasValidSession,
    account_view:
      store.appState.user && store.appState.user.account_view
        ? store.appState.user.account_view
        : false,
  };
};

const mapDispatchToProps = (dispatch) => ({
  logoutUser: (payload, meta) => dispatch(logoutUser(payload, meta)),
  getAppData: (payload, meta) => dispatch(getAppData(payload, meta)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
