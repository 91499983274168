const initialState = {
  isLoading: true,
  isUpdating: false,
  developmentID: null,
  mode: "active",
  current_page: 1,
  data: [],
  first_page_url: null,
  from: 1,
  last_page: 1,
  last_page_url: null,
  next_page_url: null,
  path: null,
  per_page: 30,
  prev_page_url: null,
  to: 1,
  total: 0,
};

const developments = (state = initialState, { type, payload, meta }) => {
  switch (type) {
    case "getDevelopmentsInit":
      return { ...state, data: [], isLoading: true };

    case "getDevelopmentsFail":
    case "getPagedDevelopmentsFail":
      return { ...state, isLoading: false };

    case "getDevelopmentsCommit":
      console.log("getDevelopmentsCommit", payload);
      return {
        ...state,
        data: payload.data,
        isLoading: false,
        developmentID:
          state.developmentID === null &&
          payload.data &&
          payload.data.length > 0
            ? payload.data[0].id
            : null,
      };
    case "getPagedDevelopmentsInit":
      return { ...state, isLoading: true, mode: payload.mode };

    case "getPagedDevelopmentsCommit":
      console.log("getPagedDevelopmentsCommit", payload);
      return {
        ...state,
        ...payload.data,
        isLoading: false,
      };

    case "setDevelopmentID":
      console.log("setDevelopmentID", payload);
      return {
        ...state,
        developmentID: payload,
      };

    case "createDevelopmentInit":
      return {
        ...state,
        isCreating: true,
      };

    case "createDevelopmentFail":
      return {
        ...state,
        isCreating: false,
      };

    case "createDevelopmentCommit":
      return {
        ...state,
        developmentID: payload.id,
        ...payload.data.developments,
        isCreating: false,
      };

    case "updateDevelopmentInit":
      return {
        ...state,
        isUpdating: true,
      };

    case "updateDevelopmentFail":
      return {
        ...state,
        isUpdating: false,
      };

    case "updateDevelopmentCommit":
      return {
        ...state,
        ...payload.data.developments,
        isUpdating: false,
      };

    case "activateDevelopmentInit":
    case "archiveDevelopmentInit":
      return {
        ...state,
        isUpdating: true,
      };
    case "activateDevelopmentFail":
    case "archiveDevelopmentFail":
      return {
        ...state,
        isUpdating: false,
      };
    case "activateDevelopmentCommit":
    case "archiveDevelopmentCommit":
      return {
        ...state,
        ...payload.data.developments,
        isUpdating: false,
      };

    case "updateRateCardCommit":
      switch (meta.action) {
        case "archive":
          return {
            ...state,
            data: state.data.map((development) => {
              if (
                development.ratecard_id === parseInt(payload.id) &&
                payload.data &&
                payload.data.other_ratecard
              ) {
                return {
                  ...development,
                  ratecard: payload.data.other_ratecard,
                  ratecard_id: parseInt(payload.data.other_ratecard.id),
                };
              }
              return development;
            }),
          };
        default:
          return state;
      }

    default:
      return state;
  }
};
export default developments;
