const initialState = {
  isLoading: true,
  isUpdating: false,
  data: [],
};

const operatives = (state = initialState, { type, payload }) => {
  switch (type) {
    case "getOperativesInit":
      return { ...state, isLoading: true };

    case "getOperativesFail":
      return { ...state, isLoading: false };

    case "getOperativesCommit":
      return {
        data: payload.data,
        isLoading: false,
      };

    default:
      return state;
  }
};
export default operatives;
