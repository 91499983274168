const Heading = ({
  children,
  color = "text-gray-900",
  size = "h1",
  weight = "font-bold",
}) => {
  let fontSize = "text-base";
  switch (size) {
    case "h1":
      fontSize = "text-4xl";
      break;
    case "h2":
      fontSize = "text-3xl";
      break;
    case "h3":
      fontSize = "text-2xl";
      break;
    case "h4":
      fontSize = "text-xl";
      break;
    case "h5":
      fontSize = "text-lg";
      break;
    case "h6":
      fontSize = "text-base";
      break;
    default:
      fontSize = "text-base";
  }

  return (
    <p className={" " + color + " " + fontSize + " " + weight}>{children}</p>
  );
};

export default Heading;
