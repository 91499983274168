import React from "react";

import Icon from "./Icon";

const Loading = ({ inLine }) => {
  const mountTime = React.useRef(Date.now());
  const mountDelay = -(mountTime.current % 1000);
  return (
    <div
      className={
        "flex text-gray-300 justify-center items-center" +
        (inLine ? " w-full h-40" : " h-full w-full")
      }
    >
      <Icon
        icon={["far", "spinner-third"]}
        size="4x"
        className="animate-spin delay-[var(--spinner-delay)]"
        style={{ "--spinner-delay": `${mountDelay}ms` }}
      />
    </div>
  );
};

export default Loading;
