var host = window.location.host;

export const subdomain = host.split('.')[0];
export const APP_URL = (subdomain === "dev" || subdomain === "portal") ? process.env.REACT_APP_URL : process.env.REACT_APP_NORTHERN_URL;
export const API_URL = (subdomain === "dev" || subdomain === "portal") ? process.env.REACT_APP_API : process.env.REACT_APP_NORTHERN_API;
console.log("subdomain",subdomain, APP_URL, API_URL);

// console.log(APP_URL, API_URL);
export const API_CONFIG = {
  responseType: "json",
  withCredentials: true,
};

export const API_MULTIPART_CONFIG = {
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
  },
  withCredentials: true,
  force: true,
};

// match tailwind
export const Breakpoints = {
  sm: "(min-width: 320px)",
  md: "(min-width: 768px)",
  lg: "(min-width: 1024px)",
  xl: "(min-width: 1280px)",
  "2xl": "(min-width: 1536px)",
  height: "(min-height: 450px)",
  tall: "(min-height: 730px)",
};

export const ROLES = {
  enrolled: "Induction",
  operative: "Operative",
  supervisor: "Area Supervisor",
  "yard-manager": "Yard Manager",
  administrator: "Administrator",
  superuser: "Super User",
};

export const STATUS = {
  //draft: "Draft", private for each user!
  pending: "Pending Approval",
  approved: "Approved",
  declined: "Declined",
  paid: "Paid",
};
