const initialState = {
  isLoading: true,
  isUpdating: false,
  userID: null,
  mode: "active",
  current_page: 1,
  data: [],
  first_page_url: null,
  from: 1,
  last_page: 1,
  last_page_url: null,
  next_page_url: null,
  path: null,
  per_page: 30,
  prev_page_url: null,
  to: 1,
  total: 0,
};

const users = (state = initialState, { type, payload }) => {
  console.log("users", type, payload);
  switch (type) {
    case "getUsersInit":
      return {
        ...state,
        mode: payload.mode,
        isLoading: true,
      };
    case "getUsersFail":
      return {
        ...state,
        isLoading: false,
      };
    case "getUsersCommit":
      return {
        ...state,
        ...payload.data.users,
        mode: payload.data.mode,
        isLoading: false,
      };

    case "setUserID":
      return {
        ...state,
        userID: payload,
      };

    case "createUserInit":
      return {
        ...state,
        isCreating: true,
      };

    case "createUserFail":
      return {
        ...state,
        isCreating: false,
      };

    case "createUserCommit":
      return {
        ...state,
        ...payload.data.users,
        userID: payload.id,
        isCreating: false,
      };

    case "updateUserInit":
      return {
        ...state,
        isUpdating: true,
      };

    case "updateUserFail":
      return {
        ...state,
        isUpdating: false,
      };

    case "updateUserCommit":
      return {
        ...state,
        ...payload.data.users,
        isUpdating: false,
      };

    case "updateProfileCommit":
      return {
        ...state,
        ...payload.data.users,
        isUpdating: false,
      };

    case "activateUserInit":
    case "archiveUserInit":
      return {
        ...state,
        isUpdating: true,
      };
    case "activateUserFail":
    case "archiveUserFail":
      return {
        ...state,
        isUpdating: false,
      };
    case "activateUserCommit":
    case "archiveUserCommit":
      return {
        ...state,
        ...payload.data.users,
        isUpdating: false,
      };

    default:
      return state;
  }
};
export default users;
