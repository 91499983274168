import React from "react";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";

import Alert from "./Alert";

const ANIMATION_DURATION = 240;

export default class Toast extends React.PureComponent {
  static propTypes = {
    /**
     * The z-index of the toast.
     */
    zIndex: PropTypes.number,

    /**
     * Duration of the toast.
     */
    duration: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),

    /**
     * Function called when the toast is all the way closed.
     */
    onRemove: PropTypes.func,

    /**
     * The type of the alert.
     */
    intent: PropTypes.oneOf([
      "info",
      "success",
      "warning",
      "error",
      "processing",
    ]).isRequired,

    /**
     * The title of the alert.
     */
    title: PropTypes.node,

    /**
     * Description of the alert.
     */
    children: PropTypes.node,

    /**
     * When true, show a close icon button inside of the toast.
     */
    hasCloseButton: PropTypes.bool,

    /**
     * When false, will close the Toast and call onRemove when finished.
     */
    isShown: PropTypes.bool,
  };

  static defaultProps = {
    intent: "info",
  };

  state = {
    isShown: true,
    height: 0,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isShown !== this.props.isShown) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        isShown: this.props.isShown,
      });
    }
  }

  componentDidMount() {
    this.startCloseTimer();
  }

  componentWillUnmount() {
    this.clearCloseTimer();
  }

  close = () => {
    this.clearCloseTimer();
    this.setState({
      isShown: false,
    });
  };

  startCloseTimer = () => {
    if (this.props.duration) {
      this.closeTimer = setTimeout(() => {
        this.close();
      }, this.props.duration * 1000);
    }
  };

  clearCloseTimer = () => {
    if (this.closeTimer) {
      clearTimeout(this.closeTimer);
      this.closeTimer = null;
    }
  };

  handleMouseEnter = () => {
    this.clearCloseTimer();
  };

  handleMouseLeave = () => {
    this.startCloseTimer();
  };

  onRef = (ref) => {
    if (ref === null) return;

    const { height } = ref.getBoundingClientRect();

    this.setState({
      height,
    });
  };

  render() {
    return (
      <CSSTransition
        appear
        unmountOnExit
        timeout={ANIMATION_DURATION}
        in={this.state.isShown}
        classNames="toast"
        onExited={this.props.onRemove}
      >
        {(state) => (
          <div
            data-state={state}
            className="toast"
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
          >
            <div className="w-full" ref={this.onRef}>
              <Alert
                className="flex-shrink-0 p-4 w-88 mx-auto rounded box-shadow pointer-events-auto"
                intent={this.props.intent}
                title={this.props.title}
                isRemoveable={this.props.hasCloseButton}
                onRemove={() => this.close()}
              >
                {this.props.children}
              </Alert>
            </div>
          </div>
        )}
      </CSSTransition>
    );
  }
}
