import axios from "axios";
import { API_URL, API_CONFIG } from "../constants";
import { handleError, successMessage } from "../utils/serverMessages";
import queryString from "query-string";

const getDevelopmentsInit = (payload, meta) => {
  return {
    type: "getDevelopmentsInit",
    payload: payload,
    meta: meta,
  };
};
export const getDevelopmentsCommit = (payload, meta) => {
  return {
    type: "getDevelopmentsCommit",
    payload: payload,
    meta: meta,
  };
};
const getDevelopmentsFail = (payload, meta) => {
  return {
    type: "getDevelopmentsFail",
    payload: payload,
    meta: meta,
  };
};

export const getDevelopments = (payload, meta) => {
  return (dispatch) => {
    const url = API_URL + "/developments";
    dispatch(getDevelopmentsInit(payload, meta));
    axios
      .get(url, API_CONFIG)
      .then((response) => {
        successMessage(response);
        dispatch(getDevelopmentsCommit(response.data, meta));
      })
      .catch((error) => {
        handleError(error, false, dispatch);
        dispatch(getDevelopmentsFail(payload, meta));
      });
  };
};

const getPagedDevelopmentsInit = (payload, meta) => {
  return {
    type: "getPagedDevelopmentsInit",
    payload: payload,
    meta: meta,
  };
};
export const getPagedDevelopmentsCommit = (payload, meta) => {
  return {
    type: "getPagedDevelopmentsCommit",
    payload: payload,
    meta: meta,
  };
};
const getPagedDevelopmentsFail = (payload, meta) => {
  return {
    type: "getPagedDevelopmentsFail",
    payload: payload,
    meta: meta,
  };
};

export const getPagedDevelopments = (payload, meta) => {
  return (dispatch) => {
    const params = queryString.stringify(payload, { arrayFormat: "comma" });
    const url = API_URL + "/paged-developments?" + params;
    dispatch(getPagedDevelopmentsInit(payload, meta));
    axios
      .get(url, API_CONFIG)
      .then((response) => {
        successMessage(response);
        dispatch(getPagedDevelopmentsCommit(response.data, meta));
      })
      .catch((error) => {
        handleError(error, false, dispatch);
        dispatch(getPagedDevelopmentsFail(payload, meta));
      });
  };
};

export const setDevelopmentID = (payload, meta) => {
  console.log("setDevelopmentID action", payload, meta);
  return {
    type: "setDevelopmentID",
    payload: payload,
    meta: meta,
  };
};

const createDevelopmentInit = (payload, meta) => {
  return {
    type: "createDevelopmentInit",
    payload: payload,
    meta: meta,
  };
};
export const createDevelopmentCommit = (payload, meta) => {
  return {
    type: "createDevelopmentCommit",
    payload: payload,
    meta: meta,
  };
};
const createDevelopmentFail = (payload, meta) => {
  return {
    type: "createDevelopmentFail",
    payload: payload,
    meta: meta,
  };
};

export const createDevelopment = (payload, meta) => {
  return (dispatch) => {
    const url = API_URL + "/developments";
    dispatch(createDevelopmentInit(payload, meta));
    axios
      .post(url, payload, API_CONFIG)
      .then((response) => {
        successMessage(response);
        if (meta && meta.close) {
          meta.close();
        }
        dispatch(createDevelopmentCommit(response.data, meta));
      })
      .catch((error) => {
        handleError(error, false, dispatch);

        dispatch(createDevelopmentFail(payload, meta));
      });
  };
};

const updateDevelopmentInit = (payload, meta) => {
  return {
    type: "updateDevelopmentInit",
    payload: payload,
    meta: meta,
  };
};
export const updateDevelopmentCommit = (payload, meta) => {
  return {
    type: "updateDevelopmentCommit",
    payload: payload,
    meta: meta,
  };
};
const updateDevelopmentFail = (payload, meta) => {
  return {
    type: "updateDevelopmentFail",
    payload: payload,
    meta: meta,
  };
};

export const updateDevelopment = (payload, meta) => {
  return (dispatch) => {
    const url = API_URL + "/developments/" + meta.action + "/" + payload.id;
    dispatch(updateDevelopmentInit(payload, meta));
    axios
      .put(url, payload, API_CONFIG)
      .then((response) => {
        successMessage(response);
        if (meta && meta.close) {
          meta.close();
        }
        dispatch(updateDevelopmentCommit(response.data, meta));
      })
      .catch((error) => {
        handleError(error, false, dispatch);

        dispatch(updateDevelopmentFail(payload, meta));
      });
  };
};

const activateDevelopmentInit = (payload, meta) => {
  return {
    type: "activateDevelopmentInit",
    payload: payload,
    meta: meta,
  };
};
export const activateDevelopmentCommit = (payload, meta) => {
  return {
    type: "activateDevelopmentCommit",
    payload: payload,
    meta: meta,
  };
};
const activateDevelopmentFail = (payload, meta) => {
  return {
    type: "activateDevelopmentFail",
    payload: payload,
    meta: meta,
  };
};

export const activateDevelopment = (payload, meta) => {
  return (dispatch) => {
    const url = API_URL + "/developments/activate/" + payload.id;
    dispatch(activateDevelopmentInit(payload, meta));
    axios
      .put(url, payload, API_CONFIG)
      .then((response) => {
        successMessage(response);
        if (meta && meta.close) {
          meta.close();
        }
        dispatch(activateDevelopmentCommit(response.data, meta));
      })
      .catch((error) => {
        handleError(error, false, dispatch);

        dispatch(activateDevelopmentFail(payload, meta));
      });
  };
};

const archiveDevelopmentInit = (payload, meta) => {
  return {
    type: "archiveDevelopmentInit",
    payload: payload,
    meta: meta,
  };
};
export const archiveDevelopmentCommit = (payload, meta) => {
  return {
    type: "archiveDevelopmentCommit",
    payload: payload,
    meta: meta,
  };
};
const archiveDevelopmentFail = (payload, meta) => {
  return {
    type: "archiveDevelopmentFail",
    payload: payload,
    meta: meta,
  };
};

export const archiveDevelopment = (payload, meta) => {
  return (dispatch) => {
    const url = API_URL + "/developments/archive/" + payload.id;
    dispatch(archiveDevelopmentInit(payload, meta));
    axios
      .put(url, payload, API_CONFIG)
      .then((response) => {
        successMessage(response);
        if (meta && meta.close) {
          meta.close();
        }
        dispatch(archiveDevelopmentCommit(response.data, meta));
      })
      .catch((error) => {
        handleError(error, false, dispatch);

        dispatch(archiveDevelopmentFail(payload, meta));
      });
  };
};
