import { logoutUser } from "../actions/appState";
import { toaster } from "./toaster";

export const handleError = (error, transID = false, dispatch) => {
  // console.error(error, transID);
  errorMessage(error, transID);
  if (
    error.response &&
    (error.response.status === 401 || error.response.status === 419)
  ) {
    dispatch(logoutUser());
  }
};

export const initMessage = (message, transID = false) => {
  // console.log(message, transID);
  toaster.notify(message, {
    id: transID,
    duration: false,
  });
};

export const processingMessage = (message, settings, transID = false) => {
  // console.log(message, transID);
  toaster.notify(message, {
    id: transID,
    duration: false,
    ...settings,
  });
};

export const errorMessage = (error, transID = false) => {
  let message = "Network Error.";
  const ErrorMessages = {
    400: "There was a problem processing your request", // not being used currently
    401: "Unauthorized, you are not allowed",
    403: "You do not have permission to action this",
    404: "Request is missing or undefined",
    405: "Request method not allowed",
    419: "Your session has expired, please login again",
    422: "The data you submitted is not valid, please check and try again",
    500: "Server error, please try again later",
    request: "There is a problem with our Servers, Please try again later",
    other: "There was a problem with your Request, Please try again later",
  };
  if (error && error.response) {
    // client received an error response (5xx, 4xx)
    if (error.response.status === 400) {
      // console.log('unauthorized, logging out ...');
      message =
        error.response.data &&
        error.response.data.message &&
        error.response.data.message.text
          ? error.response.data.message.text
          : error.response.message
          ? error.response.message
          : "Sorry but there was an error: " +
            error.response.status +
            " " +
            error.response.statusText;
    } else if (error.response.status === 401) {
      // console.log('unauthorized, logging out ...');
      message = ErrorMessages["401"];
    } else if (error.response.status === 403) {
      message = ErrorMessages["403"];
    } else if (error.response.status === 404) {
      message = ErrorMessages["404"];
    } else if (error.response.status === 422) {
      // errorResponse.isValidationError = true;
      // errorResponse.errors = error.response.data.errors;
      message = ErrorMessages["422"];
    } else if (error.response.status === 405) {
      message = ErrorMessages["405"];
    } else if (error.response.status >= 500) {
      message = ErrorMessages["500"];
    } else if (error.response.status === 429) {
    }
  } else if (error && error.request) {
    message = ErrorMessages.request;
    // client never received a response, or request never left
  } else if (error instanceof Error) {
    message = error.message;
  } else if (typeof error === "string") {
    message = error;
  } else {
    //this.$buefy.toast.open({
    //  message: 'Going Too Fast hun?, Please Slow Down',
    // type: 'is-danger',
    // })
    // anything else
    message = ErrorMessages.other;
  }

  console.error(error, transID);
  // const message =
  //   error.response.data &&
  //   error.response.data.message &&
  //   error.response.data.message.text
  //     ? error.response.data.message.text
  //     : error.response.message
  //     ? error.response.message
  //     : "Sorry but there was an error: " +
  //       error.response.status +
  //       " " +
  //       error.response.statusText;
  toaster.error(message, {
    id: transID,
  });
};

export const noticeMessage = (error, transID = false) => {
  // console.log(error.response, transID);
  const message =
    error.response.data &&
    error.response.data.message &&
    error.response.data.message.text
      ? error.response.data.message.text
      : "Sorry but there was an error: " +
        error.response.status +
        " " +
        error.response.statusText;
  toaster.notify(message, {
    id: transID,
  });
};

export const successMessage = (response, transID = false) => {
  // only show success message - if we have text...
  if (response.data && response.data.message && response.data.message.text) {
    const message = response.data.message.text;
    console.log(message, transID);
    toaster.success(message, {
      id: transID,
    });
  }
};
