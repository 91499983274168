const initialState = {
  isLoading: true,
  current_page: 1,
  data: [],
  first_page_url: null,
  from: 1,
  last_page: 1,
  last_page_url: null,
  next_page_url: null,
  path: null,
  per_page: 30,
  prev_page_url: null,
  to: 1,
  total: 0,
  to_approve: [],
};

const documents = (state = initialState, { type, payload }) => {
  switch (type) {
    case "getDocumentUsersInit":
      return {
        ...state,
        isLoading: true,
      };
    case "getDocumentsFail":
      return {
        ...state,
        isLoading: false,
      };
    case "getDocumentUsersCommit":
      return {
        ...state,
        ...payload.data.users,
        isLoading: false,
      };

    default:
      return state;
  }
};
export default documents;
