const initialState = {
  isLoading: true,
  isUpdating: false,
  ratecardID: null,
  mode: "active",
  data: [],
};

const ratecards = (state = initialState, { type, payload, meta }) => {
  switch (type) {
    case "getAppDataCommit":
    case "loginUserCommit":
      return {
        ...state,
        data: payload.ratecards,
        isLoading: false,
        isUpdating: false,
      };
    case "setRatecardID":
      return {
        ...state,
        ratecardID: payload,
      };
    case "changeCardMode":
      return {
        ...state,
        mode: payload.mode,
      };

    case "updateRateCardInit":
      switch (meta.action) {
        case "rates":
          return {
            ...state,
            data: state.data.map((ratecard) => {
              if (ratecard.id === parseInt(payload.id)) {
                return { ...ratecard, rates: payload.rates };
              }
              return ratecard;
            }),
            isUpdating: true,
          };
        case "delete-rate":
          return {
            ...state,
            data: state.data.map((ratecard) => {
              if (ratecard.id === parseInt(payload.id)) {
                return {
                  ...ratecard,
                  rates: ratecard.rates.filter((ratecard) => {
                    return ratecard.id !== parseInt(payload.rate_id);
                  }),
                };
              }
              return ratecard;
            }),
            isUpdating: true,
          };
        default:
          return {
            ...state,
            isUpdating: true,
          };
      }

    case "updateRateCardFail":
      return {
        ...state,
        isUpdating: false,
      };

    case "updateRateCardCommit":
      switch (meta.action) {
        case "rates":
        case "delete-rate":
          return state;
        case "ratecard-section":
        case "ratecard-rate":
          return {
            ...state,
            data: state.data.map((ratecard) => {
              if (ratecard.id === parseInt(payload.id)) {
                const rate = ratecard.rates.find(
                  (rate) => rate.id === payload.data.rate.id
                );
                if (!rate) {
                  let newRates = ratecard.rates.slice();
                  newRates.splice(0, 0, payload.data.rate);
                  return {
                    ...ratecard,
                    rates: newRates,
                  };
                } else {
                  return {
                    ...ratecard,
                    rates: ratecard.rates.map((rate) => {
                      if (rate.id === payload.data.rate.id) {
                        return payload.data.rate;
                      }
                      return rate;
                    }),
                  };
                }
              }
              return ratecard;
            }),
            isUpdating: false,
          };
        case "copy":
          return {
            ...state,
            isUpdating: false,
          };

        default:
          return {
            ...state,
            data: state.data.map((ratecard) => {
              if (ratecard.id === parseInt(payload.id)) {
                return payload.data.ratecard;
              }
              if (
                payload.data &&
                payload.data.other_ratecard &&
                ratecard.id === parseInt(payload.data.other_ratecard.id)
              ) {
                return payload.data.other_ratecard;
              }
              return ratecard;
            }),
            isUpdating: false,
          };
      }

    case "showHideRate":
      console.warn("showHideRate", payload);
      return {
        ...state,
        data: state.data.map((ratecard) => {
          if (ratecard.id === payload.ratecard_id) {
            return {
              ...ratecard,
              rates: ratecard.rates.map((rate) => {
                if (rate.id === payload.id) {
                  console.log("rate found", payload.open);
                  return payload;
                }
                return rate;
              }),
            };
          }
          return ratecard;
        }),
      };

    default:
      return state;
  }
};
export default ratecards;
