const TextLinkButton = ({
  children,
  type = "button",
  className = "text-blue-600 px-1 rounded-sm focus:bg-gray-200 focus:outline-none focus:shadow-outline",
  ...rest
}) => {
  return (
    <button type={type} className={className} {...rest}>
      {children}
    </button>
  );
};

export default TextLinkButton;
