import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import Icon from "./Icon";
import Logo from "./Logo";
import UserInfo from "./UserInfo";
import ExportEntries from "../components/dialogs/ExportEntries";

const NavigationDesktop = ({
  user = false,
  timesheet_week = "",
  to_approve_count = 0,
}) => {
  const [showExport, setShowExport] = useState(false);
  const createButton = (title, url, icon, exact, count = 0) => {
    return (
      <div className="flex-1 md:flex-none">
        <NavLink
          exact={exact}
          to={"/" + url}
          className="flex flex-row px-4 py-3 justify-left opacity-75 text-white items-center hover:bg-primary-hover active:bg-primary-active focus:outline-none"
          activeClassName="bg-primary-active !opacity-100"
        >
          {icon && (
            <div className="text-center mr-4">
              <Icon
                icon={["far", icon]}
                fixedWidth
                size="lg"
                className="text-current"
              />
            </div>
          )}
          {title}
          {count > 0 && (
            <span className="text-rose-600 font-bold text-sm ml-auto leading-6 px-2 w-10 text-center bg-white rounded-full">
              {count}
            </span>
          )}
        </NavLink>
      </div>
    );
  };

  return (
    <div className="fixed bottom-0 left-0 z-20 flex flex-none flex-col w-screen md:w-64 md:top-0 md:safe-pt justify-between bg-primary border-r border-gray-900 border-opacity-25">
      <div className="flex flex-1 md:flex-col overflow-y-auto">
        <div className="bg-primary-active h-20 text-white flex items-center px-4">
          <Logo type={user && user.account_view} height="4rem" />
        </div>
        <div className="bg-primary-hover py-2 px-4 flex flex-row items-center">
          <UserInfo user={user} />
        </div>
        <div className="border-t border-primary-active py-2">
          {createButton("Overview", "", "tachometer-alt-fast", true)}
          {user &&
            user.role === "operative" &&
            createButton(
              "Timesheets",
              "timesheets/" + timesheet_week,
              "stopwatch",
              false
            )}
          {user &&
            user.role !== "operative" &&
            createButton(
              "Timesheet Approvals",
              "timesheet-approvals/",
              "user-clock",
              false
            )}
          {user &&
            (user.role === "administrator" ||
              user.role === "superuser" ||
              user.role === "supervisor" ||
              (user.role === "operative" &&
                user.permissions &&
                user.permissions.request_loads)) &&
            createButton(
              "Developments",
              "developments/",
              "building",
              false
            )}{" "}
          {user &&
            (user.role === "administrator" || user.role === "superuser") &&
            createButton("Rate Cards", "rate-cards/", "balance-scale", false)}
          {createButton(
            "Health & Safety",
            "health-safety/",
            "first-aid",
            true,
            to_approve_count
          )}
          {user &&
            (user.role === "administrator" || user.role === "superuser") &&
            createButton("Users", "users/", "users", false)}
        </div>
      </div>
      <div className="flex flex-col flex-none bg-primary-hover ">
        <div className="border-t border-primary-active py-2">
          {user &&
            (user.role === "supervisor" ||
              user.role === "administrator" ||
              user.role === "superuser") && (
              <div className="relative flex-1 md:flex-none">
                <button
                  type="button"
                  className="flex flex-row w-full px-4 py-3 justify-left opacity-75 text-white items-center hover:bg-primary-hover active:bg-primary-active focus:outline-none"
                  onClick={() => {
                    setShowExport(true);
                  }}
                >
                  <div className="text-center mr-4">
                    <Icon
                      icon={["far", "file-export"]}
                      fixedWidth
                      size="lg"
                      className="text-current"
                    />
                  </div>
                  Export Timesheets
                </button>
              </div>
            )}
          {createButton("Settings", "settings/", "user-cog", true)}
        </div>
      </div>
      <ExportEntries
        user={user}
        isShown={showExport}
        onCloseComplete={() => {
          setShowExport(false);
        }}
      />
    </div>
  );
};

export default NavigationDesktop;
