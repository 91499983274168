import React, { Component } from "react";
import { connect } from "react-redux";

import { Formik, Form, Field } from "formik";

import Dialog from "../../ui/Dialog";
import Loading from "../../ui/Loading";

import Button from "../../ui/Button";
import Divider from "../../ui/Divider";

import { getOperatives } from "../../actions/operatives";

class OperativePicker extends Component {
  render() {
    const {
      isShown,
      user,
      onCloseComplete,
      updateOperative,
      operatives,
      selected,
      disableButtons,
      multiple,
      isRequired,
    } = this.props;
    return (
      <Dialog
        title={"Select Operative"}
        isShown={isShown}
        onCloseComplete={onCloseComplete}
        onOpenComplete={() => {
          // fetch all operatives from server
          this.props.getOperatives({ forManager: this.props.forManager });
        }}
        disableButtons={disableButtons}
        hideFooter
        fullHeight
        narrow
      >
        {({ close }) => (
          <Formik
            initialValues={{
              operative: multiple
                ? selected
                  ? selected
                  : []
                : selected
                ? "ID" + selected
                : "",
            }}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={(values) => {
              updateOperative(
                values,
                multiple
                  ? operatives.data.filter((operative) =>
                      values.operative.includes("ID" + operative.id)
                    )
                  : operatives.data.find(
                      (operative) => values.operative === "ID" + operative.id
                    ),
                close
              );
            }}
          >
            {({ errors, touched, isSubmitting, values }) => (
              <Form className="w-full relative flex flex-col items-stretch">
                <Divider />
                {operatives.isLoading && (
                  <div className="z-10 absolute inset-0 bg-white bg-opacity-75">
                    <Loading />
                  </div>
                )}
                <div className="relative w-full h-full flex flex-col items-stretch overflow-auto">
                  {!isRequired && (
                    <label className="p-2 border border-gray-200 rounded mb-1">
                      <Field
                        name="operative"
                        type={"radio"}
                        value=""
                        className="mr-2"
                      />
                      No Operative
                    </label>
                  )}
                  {operatives.data.map((operative) => {
                    return (
                      <label
                        key={operative.id}
                        className="p-2 border border-gray-200 rounded mb-1"
                      >
                        <Field
                          name="operative"
                          type={multiple ? "checkbox" : "radio"}
                          value={"ID" + operative.id}
                          className="mr-2"
                        />
                        {operative.display_name}
                      </label>
                    );
                  })}
                </div>

                <Divider />
                <div className="">
                  {user && user.isUpdating && (
                    <Button fullWidth appearance="dark" disabled type="button">
                      Updating...
                    </Button>
                  )}

                  {(!user || !user.isUpdating) && (
                    <Button fullWidth appearance="dark" type="submit">
                      Update Operative
                    </Button>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        )}
      </Dialog>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    user: store.appState.user,
    operatives: store.operatives,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getOperatives: (payload, meta) => dispatch(getOperatives(payload, meta)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OperativePicker);
