import { library } from "@fortawesome/fontawesome-svg-core";

import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons/faSpinnerThird";
import { faWifiSlash } from "@fortawesome/pro-regular-svg-icons/faWifiSlash";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import { faEnvelope } from "@fortawesome/pro-regular-svg-icons/faEnvelope";
import { faLockAlt } from "@fortawesome/pro-regular-svg-icons/faLockAlt";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons/faInfoCircle";
import { faCheckCircle } from "@fortawesome/pro-regular-svg-icons/faCheckCircle";
import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons/faPlusCircle";
import { faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons/faExclamationTriangle";
import { faExclamationCircle } from "@fortawesome/pro-regular-svg-icons/faExclamationCircle";
import { faUserCog } from "@fortawesome/pro-regular-svg-icons/faUserCog";
import { faUserClock } from "@fortawesome/pro-regular-svg-icons/faUserClock";
import { faTachometerAltFast } from "@fortawesome/pro-regular-svg-icons/faTachometerAltFast";
import { faFirstAid } from "@fortawesome/pro-regular-svg-icons/faFirstAid";
import { faStopwatch } from "@fortawesome/pro-regular-svg-icons/faStopwatch";
import { faBars } from "@fortawesome/pro-regular-svg-icons/faBars";
import { faClock } from "@fortawesome/pro-regular-svg-icons/faClock";
import { faLongArrowLeft } from "@fortawesome/pro-regular-svg-icons/faLongArrowLeft";
import { faLongArrowRight } from "@fortawesome/pro-regular-svg-icons/faLongArrowRight";
import { faEye } from "@fortawesome/pro-regular-svg-icons/faEye";
import { faEyeSlash } from "@fortawesome/pro-regular-svg-icons/faEyeSlash";
import { faFilePdf } from "@fortawesome/pro-regular-svg-icons/faFilePdf";
import { faEllipsisV } from "@fortawesome/pro-regular-svg-icons/faEllipsisV";
import { faEllipsisH } from "@fortawesome/pro-regular-svg-icons/faEllipsisH";
import { faFilter } from "@fortawesome/pro-regular-svg-icons/faFilter";
import { faFileSearch } from "@fortawesome/pro-regular-svg-icons/faFileSearch";
import { faCloudUpload } from "@fortawesome/pro-regular-svg-icons/faCloudUpload";
import { faPencil } from "@fortawesome/pro-regular-svg-icons/faPencil";
import { faUsers } from "@fortawesome/pro-regular-svg-icons/faUsers";
import { faCheck } from "@fortawesome/pro-regular-svg-icons/faCheck";
import { faBan } from "@fortawesome/pro-regular-svg-icons/faBan";
import { faBuilding } from "@fortawesome/pro-regular-svg-icons/faBuilding";
import { faReceipt } from "@fortawesome/pro-regular-svg-icons/faReceipt";
import { faFileExport } from "@fortawesome/pro-regular-svg-icons/faFileExport";
import { faFileCheck } from "@fortawesome/pro-regular-svg-icons/faFileCheck";
import { faBalanceScale } from "@fortawesome/pro-regular-svg-icons/faBalanceScale";
import { faCopy } from "@fortawesome/pro-regular-svg-icons/faCopy";
import { faAngleRight } from "@fortawesome/pro-regular-svg-icons/faAngleRight";
import { faAngleDown } from "@fortawesome/pro-regular-svg-icons/faAngleDown";
import { faGripVertical } from "@fortawesome/pro-solid-svg-icons/faGripVertical";
import { faFolderPlus } from "@fortawesome/pro-regular-svg-icons/faFolderPlus";
import { faCoins } from "@fortawesome/pro-regular-svg-icons/faCoins";
import { faDollyEmpty } from "@fortawesome/pro-regular-svg-icons/faDollyEmpty";
import { faDollyFlatbedAlt } from "@fortawesome/pro-regular-svg-icons/faDollyFlatbedAlt";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";

const IconLibrary = (props) => {
  library.add(
    faPlus,
    faDollyFlatbedAlt,
    faDollyEmpty,
    faCoins,
    faFolderPlus,
    faGripVertical,
    faAngleRight,
    faAngleDown,
    faCopy,
    faBalanceScale,
    faFileExport,
    faFileCheck,
    faSpinnerThird,
    faBuilding,
    faReceipt,
    faUsers,
    faBan,
    faCheck,
    faFileSearch,
    faWifiSlash,
    faEllipsisV,
    faEllipsisH,
    faTimes,
    faEnvelope,
    faLockAlt,
    faInfoCircle,
    faCheckCircle,
    faExclamationTriangle,
    faExclamationCircle,
    faUserCog,
    faTachometerAltFast,
    faFirstAid,
    faStopwatch,
    faBars,
    faClock,
    faUserClock,
    faLongArrowLeft,
    faLongArrowRight,
    faEyeSlash,
    faEye,
    faPlusCircle,
    faFilePdf,
    faFilter,
    faCloudUpload,
    faPencil
  );
};

export default IconLibrary;
