const Label = ({
  className = "block text-sm text-gray-700 mb-1",
  children,
  ...rest
}) => {
  return (
    <label className={className} {...rest}>
      {children}
    </label>
  );
};

export default Label;
