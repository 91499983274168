import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import Icon from "./Icon";
import Logo from "./Logo";
import UserInfo from "./UserInfo";
import Dialog from "./Dialog";
import ExportEntries from "../components/dialogs/ExportEntries";

class NavigationTablet extends Component {
  state = {
    menuOpen: false,
    showExport: false,
  };

  setShowExport = (value) => {
    this.setState({
      showExport: value,
    });
  };

  createButton = (title, url, icon, exact, close, count = false) => {
    return (
      <div className="relative w-full">
        <div className="m-1">
          <NavLink
            exact={exact}
            to={"/" + url}
            className="relative flex flex-col p-4 text-gray-900 items-center border border-gray-200 rounded-lg hover:bg-gray-100 active:bg-gray-100 focus:outline-none"
            activeClassName="bg-gray-100 border-primary !opacity-100"
            onClick={close}
          >
            {icon && (
              <div className="text-center text-gray-700 flex-1 w-full p-4 border-b border-gray-200 mb-2">
                <Icon
                  icon={["far", icon]}
                  fixedWidth
                  size="2x"
                  className="text-current"
                />
              </div>
            )}
            {title}
            {count > 0 && (
              <span className="absolute top-0 right-0 mt-2 mr-2 text-white font-bold text-sm  leading-6 px-2 w-10 text-center bg-rose-500 rounded-full">
                {count}
              </span>
            )}
          </NavLink>
        </div>
      </div>
    );
  };

  render() {
    const { user, timesheet_week = "", to_approve_count = 0 } = this.props;
    return (
      <div className="fixed top-0 left-0 right-0 z-20 flex flex-none flex-row w-screen md:h-20 md:safe-pt justify-between bg-primary border-b border-gray-900 border-opacity-25">
        <div className="flex w-88 flex-col overflow-y-auto">
          <div className="h-20 text-white flex items-center px-4">
            <Logo type={user && user.account_view} height="4rem" />
          </div>
        </div>
        <div className="flex-1 bg-primary-hover py-2 px-4 flex flex-row-reverse items-center">
          <UserInfo user={user} margin="mr-3" />
        </div>
        <button
          type="button"
          className="px-7 rounded-sm text-white bg-primary-active hover:bg-primary focus:bg-primary focus:outline-none focus:shadow-outline"
          onClick={() => this.setState({ menuOpen: true })}
        >
          <Icon size="2x" icon={["far", "bars"]} />
        </button>

        <Dialog
          title={" "}
          isShown={this.state.menuOpen}
          onCloseComplete={() => this.setState({ menuOpen: false })}
          hideFooter
          narrow
        >
          {({ close }) => (
            <div className="grid grid-cols-2 gap-4 w-full">
              {this.createButton(
                "Overview",
                "",
                "tachometer-alt-fast",
                true,
                close
              )}

              {user &&
                user.role === "operative" &&
                this.createButton(
                  "Timesheets",
                  "timesheets/" + timesheet_week,
                  "stopwatch",
                  false,
                  close
                )}
              {user &&
                user.role !== "operative" &&
                this.createButton(
                  "Approvals",
                  "timesheet-approvals/",
                  "user-clock",
                  false,
                  close
                )}

              {user &&
                (user.role === "administrator" ||
                  user.role === "superuser" ||
                  user.role === "supervisor" ||
                  (user.role === "operative" &&
                    user.permissions &&
                    user.permissions.request_loads)) &&
                this.createButton(
                  "Developments",
                  "developments/",
                  "building",
                  true,
                  close
                )}
              {this.createButton(
                "Health & Safety",
                "health-safety/",
                "first-aid",
                true,
                close,
                to_approve_count
              )}
              {user &&
                (user.role === "administrator" || user.role === "superuser") &&
                this.createButton("Users", "users/", "users", true, close)}

              {user &&
                (user.role === "supervisor" ||
                  user.role === "administrator" ||
                  user.role === "superuser") && (
                  <div className="relative flex-1 md:flex-none">
                    <button
                      type="button"
                      className="relative flex flex-col p-4 text-gray-900 items-center border border-gray-200 rounded-lg hover:bg-gray-100 active:bg-gray-100 focus:outline-none"
                      onClick={() => {
                        close();
                        this.setShowExport(true);
                      }}
                    >
                      <div className="text-center text-gray-700 flex-1 w-full p-4 border-b border-gray-200 mb-2">
                        <Icon
                          icon={["far", "file-export"]}
                          fixedWidth
                          size="lg"
                          className="text-current"
                        />
                      </div>
                      Export Timesheets
                    </button>
                  </div>
                )}

              {this.createButton(
                "Settings",
                "settings/",
                "user-cog",
                true,
                close
              )}
            </div>
          )}
        </Dialog>
        <ExportEntries
          user={user}
          isShown={this.state.showExport}
          onCloseComplete={() => {
            this.setShowExport(false);
          }}
        />
      </div>
    );
  }
}

export default NavigationTablet;
