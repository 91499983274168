import axios from "axios";
import { API_URL, API_CONFIG } from "../constants";
import { handleError, successMessage } from "../utils/serverMessages";

const getOperativesInit = (payload, meta) => {
  return {
    type: "getOperativesInit",
    payload: payload,
    meta: meta,
  };
};
export const getOperativesCommit = (payload, meta) => {
  return {
    type: "getOperativesCommit",
    payload: payload,
    meta: meta,
  };
};
const getOperativesFail = (payload, meta) => {
  return {
    type: "getOperativesFail",
    payload: payload,
    meta: meta,
  };
};

export const getOperatives = (payload, meta) => {
  return (dispatch) => {
    let url = API_URL + "/operatives";
    if (payload.forManager) {
      url += "?manager_id=" + payload.forManager;
    }
    dispatch(getOperativesInit(payload, meta));
    axios
      .get(url, API_CONFIG)
      .then((response) => {
        successMessage(response);
        dispatch(getOperativesCommit(response.data, meta));
      })
      .catch((error) => {
        handleError(error, false, dispatch);
        dispatch(getOperativesFail(payload, meta));
      });
  };
};
