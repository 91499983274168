import React from "react";
import { CSSTransition } from "react-transition-group";
import Portal from "./Portal";
import safeInvoke from "../utils/safe-invoke";
import preventBodyScroll from "../utils/prevent-body-scroll";
import PropTypes from "prop-types";

/**
 * Overlay is essentially a wrapper around react-transition-group/Transition
 * Learn more: https://reactcommunity.org/react-transition-group/
 */

const ANIMATION_DURATION = 240;

class Overlay extends React.Component {
  static propTypes = {
    /**
     * Children can be a node or a function accepting `close: func`
     * and `state: ENTERING | ENTERED | EXITING | EXITED`.
     */
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,

    /**
     * Show the component; triggers the enter or exit states.
     */
    isShown: PropTypes.bool,

    /**
     * Props to be passed through on the inner Box.
     */
    containerProps: PropTypes.object,

    /**
     * Whether or not to prevent body scrolling outside the context of the overlay
     */
    preventBodyScrolling: PropTypes.bool,

    /**
     * Boolean indicating if clicking the overlay should close the overlay.
     */
    shouldCloseOnClick: PropTypes.bool,

    /**
     * Boolean indicating if pressing the esc key should close the overlay.
     */
    shouldCloseOnEscapePress: PropTypes.bool,

    /**
     * Function called when overlay is about to close.
     * Return `false` to prevent the sheet from closing.
     * type: `Function -> Boolean`
     */
    onBeforeClose: PropTypes.func,

    /**
     * Callback fired before the "exiting" status is applied.
     * type: `Function(node: HtmlElement) -> void`
     */
    onExit: PropTypes.func,

    /**
     * Callback fired after the "exiting" status is applied.
     * type: `Function(node: HtmlElement) -> void`
     */
    onExiting: PropTypes.func,

    /**
     * Callback fired after the "exited" status is applied.
     * type: `Function(exitState: Any?, node: HtmlElement) -> void`
     */
    onExited: PropTypes.func,

    /**
     * Callback fired before the "entering" status is applied.
     * An extra parameter isAppearing is supplied to indicate if the enter stage
     * is occurring on the initial mount.
     *
     * type: `Function(node: HtmlElement, isAppearing: bool) -> void`
     */
    onEnter: PropTypes.func,

    /**
     * Callback fired after the "entering" status is applied.
     * An extra parameter isAppearing is supplied to indicate if the enter stage
     * is occurring on the initial mount.
     *
     * type: `Function(node: HtmlElement, isAppearing: bool) -> void`
     */
    onEntering: PropTypes.func,

    /**
     * Callback fired after the "entered" status is applied.
     * An extra parameter isAppearing is supplied to indicate if the enter stage
     * is occurring on the initial mount.
     *
     * type: `Function(node: HtmlElement, isAppearing: bool) -> void`
     */
    onEntered: PropTypes.func,
  };

  static defaultProps = {
    onHide: () => {},
    shouldCloseOnClick: true,
    shouldCloseOnEscapePress: true,
    preventBodyScrolling: false,
    onExit: () => {},
    onExiting: () => {},
    onExited: () => {},
    onEnter: () => {},
    onEntering: () => {},
    onEntered: () => {},
  };

  constructor(props) {
    super(props);
    this.containerElement = React.createRef();
    this.state = {
      exiting: false,
      exited: !props.isShown,
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isShown && this.props.isShown) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        exited: false,
      });
    }
  }

  componentWillUnmount() {
    this.handleBodyScroll(false);
    document.body.removeEventListener("keydown", this.onEsc, false);
  }

  /**
   * Methods borrowed from BlueprintJS
   * https://github.com/palantir/blueprint/blob/release/2.0.0/packages/core/src/components/overlay/overlay.tsx
   */
  bringFocusInsideOverlay = () => {
    // Always delay focus manipulation to just before repaint to prevent scroll jumping
    return requestAnimationFrame(() => {
      // Container ref may be undefined between component mounting and Portal rendering
      // activeElement may be undefined in some rare cases in IE

      if (
        this.containerElement == null || // eslint-disable-line eqeqeq, no-eq-null
        document.activeElement == null || // eslint-disable-line eqeqeq, no-eq-null
        !this.props.isShown
      ) {
        return;
      }

      const isFocusOutsideModal = !this.containerElement.current.contains(
        document.activeElement
      );
      if (isFocusOutsideModal) {
        // Element marked autofocus has higher priority than the other clowns
        const autofocusElement =
          this.containerElement.current.querySelector("[autofocus]");
        const wrapperElement =
          this.containerElement.current.querySelector("[tabindex]");
        const buttonElement =
          this.containerElement.current.querySelector("button");

        if (autofocusElement) {
          autofocusElement.focus();
        } else if (wrapperElement) {
          wrapperElement.focus();
        } else if (buttonElement) {
          buttonElement.focus();
        }
      }
    });
  };

  bringFocusBackToTarget = () => {
    return requestAnimationFrame(() => {
      if (
        this.containerElement == null || // eslint-disable-line eqeqeq, no-eq-null
        document.activeElement == null // eslint-disable-line eqeqeq, no-eq-null
      ) {
        return;
      }

      const isFocusInsideModal =
        this.containerElement &&
        this.containerElement.current &&
        this.containerElement.current.contains(document.activeElement);

      // Bring back focus on the target.
      if (
        this.previousActiveElement &&
        (document.activeElement === document.body || isFocusInsideModal)
      ) {
        this.previousActiveElement.focus();
      }
    });
  };

  onEsc = (e) => {
    // Esc key
    if (e.keyCode === 27 && this.props.shouldCloseOnEscapePress) {
      this.close();
    }
  };

  close = () => {
    const shouldClose = safeInvoke(this.props.onBeforeClose);
    if (shouldClose !== false) {
      this.setState({ exiting: true });
    }
  };

  handleBodyScroll = (preventScroll) => {
    if (this.props.preventBodyScrolling) {
      preventBodyScroll(preventScroll);
    }
  };

  handleEnter = () => {
    this.handleBodyScroll(true);
    safeInvoke(this.props.onEnter);
  };

  handleEntering = (node) => {
    document.body.addEventListener("keydown", this.onEsc, false);
    this.props.onEntering(node);
  };

  handleEntered = (node) => {
    this.previousActiveElement = document.activeElement;
    this.bringFocusInsideOverlay();
    this.props.onEntered(node);
  };

  handleExit = () => {
    this.handleBodyScroll(false);
    safeInvoke(this.props.onExit);
  };

  handleExiting = (node) => {
    document.body.removeEventListener("keydown", this.onEsc, false);
    this.bringFocusBackToTarget();
    this.props.onExiting(node);
  };

  handleExited = (node) => {
    this.setState({ exiting: false, exited: true });
    this.props.onExited(node);
  };

  handleBackdropClick = (e) => {
    if (e.target !== e.currentTarget || !this.props.shouldCloseOnClick) {
      return;
    }
    this.close();
  };

  render() {
    const { containerProps = {}, isShown, children } = this.props;

    const { exiting, exited } = this.state;

    if (exited) return null;

    return (
      <Portal>
        <CSSTransition
          nodeRef={this.containerElement}
          appear
          unmountOnExit
          timeout={ANIMATION_DURATION}
          in={isShown && !exiting}
          classNames="overlay"
          onExit={this.handleExit}
          onExiting={this.handleExiting}
          onExited={this.handleExited}
          onEnter={this.handleEnter}
          onEntering={this.handleEntering}
          onEntered={this.handleEntered}
        >
          <div
            ref={this.containerElement}
            className="overlay z-100 absolute top-0 left-0 w-full h-full flex items-start justify-center"
            onClick={this.handleBackdropClick}
            {...containerProps}
          >
            {typeof children === "function"
              ? children({
                  close: this.close,
                })
              : children}
          </div>
        </CSSTransition>
      </Portal>
    );
  }
}
export default Overlay;
