import Input from "./Input";

const InputFeatured = ({
  type = "text",
  icon = false,
  placeholder = "",
  hasError = false,
  className = (icon ? "pl-12 " : "pl-4 ") +
    (hasError
      ? "border-red-100 bg-red-50 focus:ring-red-300 focus:border-red-300  placeholder-red-500 "
      : "border-gray-100 bg-gray-50 focus:ring-gray-300 focus:border-gray-300  placeholder-gray-500 ") +
    "pr-4 py-4 text-sm  w-full appearance-none rounded-lg  focus:outline-none focus:ring-1 placeholder-gray-500",
  ...rest
}) => {
  return (
    <Input
      type={type}
      icon={icon}
      hasError={hasError}
      className={className}
      placeholder={placeholder}
      {...rest}
    />
  );
};

export default InputFeatured;
