const initialState = {
  isLoading: true,
  isUploading: false,
  isUpdating: false,
  current_page: 1,
  data: [],
  first_page_url: null,
  from: 1,
  last_page: 1,
  last_page_url: null,
  next_page_url: null,
  path: null,
  per_page: 30,
  prev_page_url: null,
  to: 1,
  total: 0,
  to_approve: [],
};

const documents = (state = initialState, { type, payload }) => {
  switch (type) {
    case "getAppDataCommit":
    case "loginUserCommit":
      return {
        ...state,
        ...payload.documents,
        to_approve: payload.documents_to_approve,
        isLoading: false,
        isUploading: false,
        isUpdating: false,
      };

    case "getDocumentsInit":
      return {
        ...state,
        isLoading: true,
      };
    case "getDocumentsFail":
      return {
        ...state,
        isLoading: false,
      };
    case "getDocumentsCommit":
      return {
        ...state,
        ...payload.data.documents,
        isLoading: false,
      };

    case "createDocumentInit":
      return {
        ...state,
        isUpdating: true,
      };

    case "createDocumentFail":
      return {
        ...state,
        isUpdating: false,
      };

    case "createDocumentCommit":
      let newArray = state.data.slice();
      newArray.splice(0, 0, payload.data);

      return {
        ...state,
        data: newArray,
        isUpdating: false,
      };

    case "updateDocumentInit":
      return {
        ...state,
        isUpdating: true,
      };

    case "updateDocumentFail":
      return {
        ...state,
        isUpdating: false,
      };

    case "updateDocumentCommit":
      return {
        ...state,
        data: state.data.map((item) => {
          if (item.id === payload.id) {
            return {
              ...item,
              ...payload.data,
            };
          }
          return item;
        }),
        isUpdating: false,
      };

    case "deleteDocumentInit":
      return {
        ...state,
        isUpdating: true,
      };

    case "deleteDocumentFail":
      return {
        ...state,
        isUpdating: false,
      };

    case "deleteDocumentCommit":
      return {
        ...state,
        data: state.data.filter((doc) => {
          return doc.id !== payload.id;
        }),
        isUpdating: false,
      };

    case "markAsReadInit":
      return {
        ...state,
        isUpdating: true,
      };

    case "markAsReadFail":
      return {
        ...state,
        isUpdating: false,
      };

    case "markAsReadCommit":
      return {
        ...state,
        to_approve: payload.data,
        isUpdating: false,
      };

    default:
      return state;
  }
};
export default documents;
