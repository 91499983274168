import React from "react";
import ReactDOM from "react-dom";
import ToastManager from "./toast-manager";

const isBrowser =
    typeof window !== "undefined" && typeof window.document !== "undefined";

let toastContainer;

/**
 * The Toaster manages the interactionsb between
 * the ToasterManger and the toast API.
 */
export default class ToasterAPI {
    constructor() {
        if (!isBrowser) return;

        if (!toastContainer) {
            toastContainer = document.createElement("div");
            toastContainer.setAttribute(
                "data-seismicsix-toaster-container",
                ""
            );
            // toastContainer.classList.add("absolute", "z-99", "inset-0");
            document.body.appendChild(toastContainer);
        }

        ReactDOM.render(
            <ToastManager
                bindNotify={this._bindNotify}
                bindRemove={this._bindRemove}
                bindGetToasts={this._bindGetToasts}
                bindCloseAll={this._bindCloseAll}
            />,
            toastContainer
        );
    }

    _bindNotify = (handler) => {
        this.notifyHandler = handler;
    };

    _bindRemove = (handler) => {
        this.removeHandler = handler;
    };

    _bindGetToasts = (handler) => {
        this.getToastsHandler = handler;
    };

    _bindCloseAll = (handler) => {
        this.closeAllHandler = handler;
    };

    getToasts = () => {
        return this.getToastsHandler();
    };

    closeAll = () => {
        return this.closeAllHandler();
    };

    processing = (title, settings = {}) => {
        return this.notifyHandler(title, { ...settings, intent: "processing" });
    };
    notify = (title, settings = {}) => {
        return this.notifyHandler(title, { ...settings, intent: "info" });
    };

    success = (title, settings = {}) => {
        return this.notifyHandler(title, { ...settings, intent: "success" });
    };

    warning = (title, settings = {}) => {
        return this.notifyHandler(title, { ...settings, intent: "warning" });
    };

    error = (title, settings = {}) => {
        return this.notifyHandler(title, { ...settings, intent: "error" });
    };

    remove = (id) => {
        return this.removeHandler(id);
    };
}

export const toaster = new ToasterAPI();
