const initialState = {
  isLoading: true,
  isUpdating: false,
  mode: "pending",
  entryID: null,
  current_page: 1,
  data: [],
  first_page_url: null,
  from: 1,
  last_page: 1,
  last_page_url: null,
  next_page_url: null,
  path: null,
  per_page: 30,
  prev_page_url: null,
  to: 1,
  total: 0,
};

const approvalEntries = (state = initialState, { type, payload }) => {
  switch (type) {
    case "getAppDataCommit":
    case "loginUserCommit":
      return {
        ...state,
        ...payload.approvals,
        isLoading: false,
        isUpdating: false,
        mode:
          payload.user &&
          (payload.user.role === "administrator" ||
            payload.user.role === "superuser")
            ? "approved"
            : "pending",
      };

    case "getApprovalEntriesInit":
      return {
        ...state,
        mode: payload.mode,
        isLoading: true,
      };
    case "getApprovalEntriesFail":
      return {
        ...state,
        isLoading: false,
      };
    case "getApprovalEntriesCommit":
      return {
        ...state,
        ...payload.data.approvals,
        mode: payload.data.mode,
        isLoading: false,
      };

    case "setApprovalEntryID":
      return {
        ...state,
        entryID: payload,
      };

    case "approveEntriesInit":
    case "declineEntriesInit":
    case "paidEntriesInit":
      return {
        ...state,
        isUpdating: true,
      };
    case "approveEntriesFail":
    case "declineEntriesFail":
    case "paidEntriesFail":
      return {
        ...state,
        isUpdating: false,
      };
    case "approveEntriesCommit":
    case "declineEntriesCommit":
    case "paidEntriesCommit":
      return {
        ...state,
        ...payload.data.entries,
        isUpdating: false,
      };

    case "updateEntryCommit":
      return {
        ...state,
        data: state.data.map((item) => {
          if (item.id === payload.id) {
            console.log("updated", {
              ...item,
              ...payload.data,
            });
            return {
              ...item,
              ...payload.data,
            };
          }
          return item;
        }),
        isUpdating: false,
      };
    
      case "updateWorkCommit":
        return {
          ...state,
          data: state.data.map((entry) => {
            if (entry.id === payload.entry_id) {
              entry.work = entry.work.map((work) => {
                if (work.id === payload.data.id) {
                  return payload.data;
                }
                return work;
              });
            }
            return entry;
          }),
          isUpdating: false,
        };

    default:
      return state;
  }
};
export default approvalEntries;
