import Icon from "./Icon";

const ButtonClose = ({
  type = "button",
  appearance = "default",
  margin = "",
  icon = "times",
  width = "w-10",
  height = "h-10 ",
  color = "text-gray-600  hover:text-gray-700",
  className = "rounded-sm  focus:outline-none focus:shadow-outline disabled:opacity-25",
  additonalClassName = "",
  ...rest
}) => {
  let finalClassName = "";
  switch (appearance) {
    case "transparent":
      finalClassName =
        className + " bg-transparent hover:bg-gray-200 focus:bg-gray-200 ";
      break;
    default:
      finalClassName =
        className + " bg-gray-200 hover:bg-gray-300 focus:bg-gray-300 ";
  }

  return (
    <button
      type={type}
      className={
        finalClassName +
        " " +
        color +
        " " +
        width +
        " " +
        height +
        " " +
        margin +
        " " +
        additonalClassName
      }
      {...rest}
    >
      <Icon icon={typeof icon === "string" ? ["far", icon] : icon} fixedWidth />
    </button>
  );
};

export default ButtonClose;
