import React, { Component } from "react";
import { Manager, Reference, Popper } from "react-popper";
import Portal from "./Portal";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";

const ANIMATION_DURATION = 140;

// const modifiers = {
//   preventOverflow: {
//     enabled: true,
//     escapeWithReference: true,
//     boundariesElement: 'scrollParent'
//   },
//   flip: {
//     enabled: true,
//     boundariesElement: 'viewport'
//   },
//   hide: { enabled: false },
//   // We disable the built-in gpuAcceleration so that
//   // Popper.js will return us easy to interpolate values
//   // (top, left instead of transform: translate3d)
//   // We'll then use these values to generate the needed
//   // css tranform values blended with the react-spring values
//   computeStyle: { gpuAcceleration: false },
// }

const modifiers = [
  {
    name: "preventOverflow",
    enabled: true,
  },
  {
    name: "flip",
    enabled: true,
  },
  {
    name: "hide",
    enabled: false,
  },
  {
    name: "computeStyles",
    options: {
      gpuAcceleration: false, // true by default
    },
  },
];

export default class Popover extends Component {
  static propTypes = {
    /**
     * The position the Popover is on. Smart positioning might override this.
     */
    placement: PropTypes.oneOf([
      "top-start",
      "top",
      "top-end",
      "right-start",
      "right",
      "right-end",
      "bottom-start",
      "bottom",
      "bottom-end",
      "left-start",
      "left",
      "left-end",
    ]),

    /**
     * Open the Popover based on click or hover. Default is click.
     */
    trigger: PropTypes.oneOf(["click", "hover"]),

    /**
     * The content of the Popover.
     */
    // btnContent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,

    /**
     * The content of the Popover.
     */
    content: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,

    /**
     * Function called when the Popover opens.
     */
    onOpen: PropTypes.func.isRequired,

    /**
     * Function fired when Popover closes.
     */
    onClose: PropTypes.func.isRequired,

    /**
     * Function that will be called when the enter transition is complete.
     */
    onOpenComplete: PropTypes.func.isRequired,

    /**
     * Function that will be called when the exit transition is complete.
     */
    onCloseComplete: PropTypes.func.isRequired,

    /**
     * Function that will be called when the body is clicked.
     */
    onBodyClick: PropTypes.func.isRequired,

    /**
     * When true, bring focus inside of the Popover on open.
     */
    bringFocusInside: PropTypes.bool,

    /**
     * Boolean indicating if clicking outside the dialog should close the dialog.
     */
    shouldCloseOnExternalClick: PropTypes.bool,
  };

  static defaultProps = {
    onOpen: () => {},
    onClose: () => {},
    onOpenComplete: () => {},
    onCloseComplete: () => {},
    onBodyClick: () => {},
    bringFocusInside: false,
    shouldCloseOnExternalClick: true,
    trigger: "click",
    placement: "bottom-start",
  };

  constructor(props) {
    super(props);
    this.menuRef = React.createRef();
    this.btnRef = React.createRef();
    this.transitionRef = React.createRef();
  }

  state = {
    isShown: false,
    clickedItem: undefined,
  };

  componentWillUnmount() {
    document.body.removeEventListener("mousedown", this.setClickEl, false);
    document.body.removeEventListener("mouseup", this.onBodyClick, false);
    document.body.removeEventListener("keydown", this.onEsc, false);
  }

  /**
   * Methods borrowed from BlueprintJS
   * https://github.com/palantir/blueprint/blob/release/2.0.0/packages/core/src/components/overlay/overlay.tsx
   */
  bringFocusInside = () => {
    // Always delay focus manipulation to just before repaint to prevent scroll jumping
    return requestAnimationFrame(() => {
      // Container ref may be undefined between component mounting and Portal rendering
      // activeElement may be undefined in some rare cases in IE
      if (
        this.menuRef == null || // eslint-disable-line eqeqeq, no-eq-null
        document.activeElement == null || // eslint-disable-line eqeqeq, no-eq-null
        !this.state.isShown
      ) {
        return;
      }

      const isFocusOutsideModal = !this.menuRef.current.contains(
        document.activeElement
      );
      if (isFocusOutsideModal) {
        // Element marked autofocus has higher priority than the other clowns
        const autofocusElement = this.menuRef.current.querySelector(
          "[autofocus]"
        );
        const wrapperElement = this.menuRef.current.querySelector("[tabindex]");
        const buttonElements = this.menuRef.current.querySelectorAll(
          'button, a, [role="menuitem"], [role="menuitemradio"]'
        );

        if (autofocusElement) {
          autofocusElement.focus();
        } else if (wrapperElement) {
          wrapperElement.focus();
        } else if (buttonElements.length > 0) {
          buttonElements[0].focus();
        }
      }
    });
  };

  bringFocusBackToTarget = () => {
    return requestAnimationFrame(() => {
      if (
        this.menuRef == null || // eslint-disable-line eqeqeq, no-eq-null
        document.activeElement == null // eslint-disable-line eqeqeq, no-eq-null
      ) {
        return;
      }

      const isFocusInsideModal = this.menuRef.current.contains(
        document.activeElement
      );

      // Bring back focus on the target.
      if (
        this.btnRef.current &&
        (document.activeElement === document.body || isFocusInsideModal)
      ) {
        this.btnRef.current.focus();
      }
    });
  };

  setClickEl = (e) => {
    //set the item under the target on mouse down - this allows for dragging inside the popover!
    this.setState({ clickedItem: e.target });
  };

  onBodyClick = (e) => {
    // Ignore clicks on the popover or button
    if (
      this.btnRef &&
      this.btnRef.current &&
      this.btnRef.current.contains(this.state.clickedItem)
    ) {
      return;
    }
    // Ignore clicks on the popover or button
    if (
      this.menuRef &&
      this.menuRef.current &&
      this.menuRef.current.contains(this.state.clickedItem)
    ) {
      return;
    }
    // Notify body click
    this.props.onBodyClick(e);

    if (this.props.shouldCloseOnExternalClick === false) {
      return;
    }

    this.close();
  };

  toggle = () => {
    if (this.state.isShown) {
      this.close();
    } else {
      this.open();
    }
  };

  open = () => {
    if (this.state.isShown) {
      return;
    }

    this.setState({ isShown: true });
    document.body.addEventListener("mousedown", this.setClickEl, false);
    document.body.addEventListener("mouseup", this.onBodyClick, false);
    document.body.addEventListener("keydown", this.onEsc, false);

    this.props.onOpen();
  };

  close = () => {
    if (!this.state.isShown) {
      return;
    }

    this.setState({ isShown: false, clickedItem: undefined });
    document.body.removeEventListener("mousedown", this.setClickEl, false);
    document.body.removeEventListener("mouseup", this.onBodyClick, false);
    document.body.removeEventListener("keydown", this.onEsc, false);

    this.bringFocusBackToTarget();
    this.props.onClose();
  };

  handleOpenComplete = () => {
    if (this.props.bringFocusInside) this.bringFocusInside();
    this.props.onOpenComplete();
  };

  handleCloseComplete = () => {
    this.props.onCloseComplete();
  };

  handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      this.bringFocusInside();
    }
  };

  handleOpenHover = () => {
    if (this.props.trigger === "hover") {
      this.open();
    }
  };

  handleCloseHover = () => {
    if (this.props.trigger === "hover") {
      this.close();
    }
  };

  render() {
    const { content, btnContent, placement } = this.props;

    return (
      <Manager>
        <Reference innerRef={this.btnRef}>
          {({ ref }) => btnContent(ref, this.toggle, this.open, this.close)}
        </Reference>
        <CSSTransition
          nodeRef={this.menuRef}
          appear
          unmountOnExit
          timeout={ANIMATION_DURATION}
          in={this.state.isShown}
          classNames="popover"
          onEntered={this.handleOpenComplete}
          onExited={this.handleCloseComplete}
        >
          <Portal>
            <Popper
              innerRef={this.menuRef}
              placement={placement}
              modifiers={modifiers}
            >
              {({ placement, ref, style }) => (
                <div
                  ref={ref}
                  style={style}
                  data-placement={placement}
                  className={"popover z-100"}
                >
                  {typeof content === "function"
                    ? content({ close: this.close })
                    : content}
                </div>
              )}
            </Popper>
          </Portal>
        </CSSTransition>
      </Manager>
    );
  }
}
