import { DateTime, Interval } from "luxon";

const initialState = {
  isInitialising: true,
  isValidatingSession: false,
  hasValidSession: false,
  captchaKey: null,
  user: false,
  entries: [],
};

const appState = (state = initialState, { type, payload, meta }) => {
  switch (type) {
    case "loginUserInit":
      return { ...state, isValidatingSession: true };

    case "loginUserFail":
    case "getAppDataFail":
      return { ...state, isValidatingSession: false, hasValidSession: false };

    case "getAppDataCommit":
    case "loginUserCommit":
      return {
        user: payload.user,
        entries: payload.entries, // this weeks entries for dashboard
        captchaKey: payload.captchaKey,
        hasValidSession: payload.user ? true : false,
        isValidatingSession: false,
        isInitialising: false,
      };

    case "logoutUserCommit":
      return {
        ...payload,
        hasValidSession: false,
      };

    case "expireSession":
      return {
        ...state,
        hasValidSession: false,
      };

    case "updateProfileInit":
      return {
        ...state,
        user: { ...state.user, isUpdating: true },
      };

    case "updateProfileFail":
      return {
        ...state,
        user: { ...state.user, isUpdating: false },
      };

    case "updateProfileCommit":
      console.warn("updateUserCommit", payload.data);
      if (payload.data && payload.data.users && payload.data.users.data) {
        let user = payload.data.users.data.find(
          (o) => o.id === parseInt(payload.data.id)
        );
        console.warn("updateUserCommit :: user", user);

        return {
          ...state,
          user: { ...user },
        };
      }
      return {
        ...state,
        user: { ...state.user, ...payload.data, isUpdating: false },
      };

    case "updateUserCommit":
      console.warn("updateUserCommit", payload.data);
      if (parseInt(payload.data.id) === state.user.id) {
        return {
          ...state,
          user: { ...state.user, ...payload.data, isUpdating: false },
        };
      } else {
        return state;
      }

    // keep dashboard entries in stync!
    case "createEntryCommit":
      if(!Array.isArray(state.entries)) {
        return state;
      }
      //if entry is for this week only...
      let thisWeek = Interval.fromDateTimes(
        DateTime.utc().startOf("week"),
        DateTime.utc().endOf("week")
      );

      if (
        payload.data.date &&
        thisWeek.contains(
          DateTime.fromSQL(payload.data.date, { zone: "utc" }).startOf("week")
        )
      ) {
        let newArray = state.entries.slice();
        newArray.splice(0, 0, payload.data);

        return {
          ...state,
          entries: newArray,
        };
      }
      return state;

    case "updateEntryCommit":
      //if entry is for this week only...
      console.log("updateEntryCommit appState", state.entries);
      if(!Array.isArray(state.entries)) {
        return state;
      }
      return {
        ...state,
        entries: state.entries.map((item) => {
          if (item.id === payload.id) {
            return {
              ...item,
              ...payload.data,
            };
          }
          return item;
        }),
      };

    case "deleteEntryCommit":
      if(!Array.isArray(state.entries)) {
        return state;
      }
      return {
        ...state,
        entries: state.entries.filter((entry) => {
          return entry.id !== payload.id;
        }),
      };

    default:
      return state;
  }
};
export default appState;
