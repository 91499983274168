import Button from "./Button";

const ButtonMain = ({
  className = "py-4 px-4 rounded text-white bg-gray-900 hover:bg-gray-700 focus:bg-gray-500 focus:outline-none focus:shadow-outline disabled:opacity-25",
  ...rest
}) => {
  return <Button className={className} fullWidth {...rest} />;
};

export default ButtonMain;
