import React, { useState, useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";

import Icon from "./Icon";
import Dialog from "./Dialog";
import ExportEntries from "../components/dialogs/ExportEntries";

const NavigationMobile = ({
  user = null,
  timesheet_week = "",
  to_approve_count = 0,
}) => {
  const [showMore, setShowMore] = useState(false);
  const [showExport, setShowExport] = useState(false);
  const history = useHistory();

  useEffect(() => {
    return history.listen((location) => {
      console.warn(location);
    });
  }, [history]);

  console.warn(history.location);

  const createButton = (url, icon, exact, count = 0) => {
    return (
      <NavLink
        exact={exact}
        to={"/" + url}
        className="relative w-1/4 pb-4 text-gray-700 hover:bg-gray-100 active:bg-gray-200 focus:outline-none"
        activeClassName="!bg-gray-200"
      >
        {icon && (
          <div className="flex items-center justify-center h-20 text-sm">
            <Icon
              icon={["far", icon]}
              fixedWidth
              size="2x"
              className="text-current"
            />
          </div>
        )}
        {count > 0 && (
          <span className="absolute top-0 right-0 mt-2 mr-2 text-white font-bold text-sm  leading-6 px-2 w-10 text-center bg-rose-500 rounded-full">
            {count}
          </span>
        )}
      </NavLink>
    );
  };

  const createTextButton = (title, url, icon, exact, closeModal, count = 0) => {
    return (
      <div className="relative flex-1 md:flex-none">
        <NavLink
          exact={exact}
          to={"/" + url}
          className="flex flex-row px-4 py-3 justify-left opacity-75 border-t border-gray-200 text-gray-700 hover:bg-gray-100 active:bg-gray-200 focus:outline-none"
          activeClassName="bg-gray-200 !opacity-100"
          onClick={closeModal}
        >
          {icon && (
            <div className="text-center mr-4">
              <Icon
                icon={["far", icon]}
                fixedWidth
                size="lg"
                className="text-current"
              />
            </div>
          )}
          {title}
          {count > 0 && (
            <span className="ml-auto text-white font-bold text-sm leading-6 px-2 w-10 text-center bg-rose-500 rounded-full">
              {count}
            </span>
          )}
        </NavLink>
      </div>
    );
  };

  return (
    <div className="fixed bottom-0 left-0 right-0 z-20 safe-pl safe-pr flex flex-row w-screen border-t-2 border-gray-200 bg-white">
      {createButton("", "tachometer-alt-fast", true)}
      {user &&
        user.role === "operative" &&
        createButton("timesheets/" + timesheet_week, "stopwatch", false)}
      {user &&
        user.role !== "operative" &&
        createButton("timesheet-approvals/", "user-clock", false)}
      {user &&
        user.role !== "administrator" &&
        user.role !== "superuser" &&
        createButton("health-safety/", "first-aid", true, to_approve_count)}
      {user &&
        (user.role === "administrator" || user.role === "superuser") &&
        createButton("users/", "users", true)}
      {/*user &&
        user.role !== "administrator" &&
        user.role !== "superuser" &&
        user.role !== "supervisor" &&
        createButton("settings/", "user-cog", true)*/}
      {/*user &&
        (user.role === "supervisor" ||
          user.role === "administrator" ||
          user.role === "superuser") && (
          <button
            onClick={() => setShowMore(true)}
            className="w-1/4 pb-4 text-gray-700 hover:bg-gray-100 active:bg-gray-200 focus:outline-none"
          >
            <div className="flex items-center justify-center h-20 text-sm">
              <Icon
                icon={["far", "ellipsis-h"]}
                fixedWidth
                size="2x"
                className="text-current"
              />
            </div>
          </button>
        )*/}

      <button
        onClick={() => setShowMore(true)}
        className="w-1/4 pb-4 text-gray-700 hover:bg-gray-100 active:bg-gray-200 focus:outline-none"
      >
        <div className="flex items-center justify-center h-20 text-sm">
          <Icon
            icon={["far", "ellipsis-h"]}
            fixedWidth
            size="2x"
            className="text-current"
          />
        </div>
      </button>

      <Dialog
        title={"Menu"}
        isShown={showMore}
        onCloseComplete={() => {
          setShowMore(false);
        }}
        hideFooter
      >
        {({ close: closeModal }) => (
          <div className="flex flex-col flex-1">
            {user &&
              (user.role === "administrator" ||
                user.role === "superuser" ||
                user.role === "supervisor" ||
                (user.role === "operative" &&
                  user.permissions &&
                  user.permissions.request_loads)) &&
              createTextButton(
                "Developments",
                "developments/",
                "building",
                true,
                closeModal
              )}
            {user &&
              (user.role === "administrator" || user.role === "superuser") &&
              createTextButton(
                "Health & Safety",
                "health-safety/",
                "first-aid",
                true,
                closeModal,
                to_approve_count
              )}

            {user &&
              (user.role === "supervisor" ||
                user.role === "administrator" ||
                user.role === "superuser") && (
                <div className="relative flex-1 md:flex-none">
                  <button
                    type="button"
                    className="flex flex-row w-full px-4 py-3 justify-left opacity-75 border-t border-gray-200 text-gray-700 hover:bg-gray-100 active:bg-gray-200 focus:outline-none"
                    onClick={() => {
                      closeModal();
                      setShowExport(true);
                    }}
                  >
                    <div className="text-center mr-4">
                      <Icon
                        icon={["far", "file-export"]}
                        fixedWidth
                        size="lg"
                        className="text-current"
                      />
                    </div>
                    Export Timesheets
                  </button>
                </div>
              )}

            {createTextButton(
              "Settings",
              "settings/",
              "user-cog",
              true,
              closeModal
            )}
          </div>
        )}
      </Dialog>
      <ExportEntries
        user={user}
        isShown={showExport}
        onCloseComplete={() => {
          setShowExport(false);
        }}
      />
    </div>
  );
};

export default NavigationMobile;
