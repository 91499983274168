import Paragraph from "./Paragraph";
import Strong from "./Strong";

const UserInfo = ({ user = false, margin = "", isDashboard = false }) => {
  var today = new Date();
  var curHr = today.getHours();
  var greeting = "";

  if (curHr < 12) {
    greeting = "Good Morning";
  } else if (curHr < 18) {
    greeting = "Good Afternoon";
  } else {
    greeting = "Good Evening";
  }

  return (
    <>
      <div className={"flex-1"}>
        <Paragraph className="text-white leading-tight">
          <Strong>{greeting}</Strong>
        </Paragraph>
        <Paragraph className="text-white leading-tight">
          {user && user.display_name}
        </Paragraph>
      </div>
      <div
        className={
          (isDashboard
            ? "text-white bg-primary-active "
            : "bg-white text-primary ") +
          " " +
          margin +
          " w-12 h-12 font-bold rounded-lg flex flex-row items-center justify-center"
        }
      >
        {user && user.first_name.charAt(0)}
        {user && user.last_name.charAt(0)}
      </div>
    </>
  );
};

export default UserInfo;
