import axios from "axios";
import { API_URL, API_CONFIG } from "../constants";
import { handleError, successMessage } from "../utils/serverMessages";

const getAppDataInit = (payload, meta) => {
  return {
    type: "getAppDataInit",
    payload: payload,
    meta: meta,
  };
};
export const getAppDataCommit = (payload, meta) => {
  return {
    type: "getAppDataCommit",
    payload: payload,
    meta: meta,
  };
};
const getAppDataFail = (payload, meta) => {
  return {
    type: "getAppDataFail",
    payload: payload,
    meta: meta,
  };
};

export const getAppData = (payload, meta) => {
  return (dispatch) => {
    const url = API_URL + "/app-data";
    dispatch(getAppDataInit(payload, meta));
    axios
      .get(url, API_CONFIG)
      .then((response) => {
        successMessage(response);
        dispatch(getAppDataCommit(response.data, meta));
      })
      .catch((error) => {
        handleError(error, false, dispatch);
        dispatch(getAppDataFail(payload, meta));
      });
  };
};

const getCSRFInit = (payload, meta) => {
  return {
    type: "getCSRFInit",
    payload: payload,
    meta: meta,
  };
};
export const getCSRFCommit = (payload, meta) => {
  return {
    type: "getCSRFCommit",
    payload: payload,
    meta: meta,
  };
};
const getCSRFFail = (payload, meta) => {
  return {
    type: "getCSRFFail",
    payload: payload,
    meta: meta,
  };
};

export const getCSRF = (payload, meta) => {
  return (dispatch) => {
    const url = API_URL + "/auth/csrf-cookie";
    dispatch(getCSRFInit(payload, meta));
    axios
      .get(url, API_CONFIG)
      .then((response) => {
        successMessage(response);
        dispatch(getCSRFCommit(response.data, meta));
      })
      .catch((error) => {
        handleError(error, false, dispatch);
        dispatch(getCSRFFail(payload, meta));
      });
  };
};

const loginUserInit = (payload, meta) => {
  return {
    type: "loginUserInit",
    payload: payload,
    meta: meta,
  };
};
export const loginUserCommit = (payload, meta) => {
  return {
    type: "loginUserCommit",
    payload: payload,
    meta: meta,
  };
};
const loginUserFail = (payload, meta) => {
  return {
    type: "loginUserFail",
    payload: payload,
    meta: meta,
  };
};

export const loginUser = (payload, meta) => {
  return (dispatch) => {
    const url = API_URL + "/auth/login";
    dispatch(loginUserInit(payload, meta));
    axios
      .post(url, payload, API_CONFIG)
      .then((response) => {
        successMessage(response);
        dispatch(loginUserCommit(response.data, meta));
      })
      .catch((error) => {
        handleError(error, false, dispatch);
        dispatch(loginUserFail(payload, meta));
      });
  };
};

const logoutUserInit = (payload, meta) => {
  return {
    type: "logoutUserInit",
    payload: payload,
    meta: meta,
  };
};
export const logoutUserCommit = (payload, meta) => {
  return {
    type: "logoutUserCommit",
    payload: payload,
    meta: meta,
  };
};
const logoutUserFail = (payload, meta) => {
  return {
    type: "logoutUserFail",
    payload: payload,
    meta: meta,
  };
};

export const logoutUser = (payload, meta) => {
  return (dispatch) => {
    const url = API_URL + "/auth/logout";
    dispatch(logoutUserInit(payload, meta));
    axios
      .get(url, API_CONFIG)
      .then((response) => {
        successMessage(response);
        dispatch(logoutUserCommit(response.data, meta));
      })
      .catch((error) => {
        handleError(error, false, dispatch);
        dispatch(logoutUserFail(payload, meta));
      });
  };
};

export const expireSession = (payload, meta) => {
  return {
    type: "expireSession",
    payload: payload,
    meta: meta,
  };
};

const updateProfileInit = (payload, meta) => {
  return {
    type: "updateProfileInit",
    payload: payload,
    meta: meta,
  };
};
export const updateProfileCommit = (payload, meta) => {
  return {
    type: "updateProfileCommit",
    payload: payload,
    meta: meta,
  };
};
const updateProfileFail = (payload, meta) => {
  return {
    type: "updateProfileFail",
    payload: payload,
    meta: meta,
  };
};

export const updateProfile = (payload, meta) => {
  return (dispatch) => {
    const url = API_URL + "/user-action/" + meta.action + "/" + payload.id;

    dispatch(updateProfileInit(payload, meta));
    axios
      .put(url, payload, API_CONFIG)
      .then((response) => {
        successMessage(response);
        if (meta.close) {
          meta.close();
        }
        dispatch(updateProfileCommit(response.data, meta));
      })
      .catch((error) => {
        handleError(error, false, dispatch);

        dispatch(updateProfileFail(payload, meta));
      });
  };
};
