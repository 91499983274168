const initialState = {
  entryID: null,
  isLoading: true,
  isUpdating: false,
  week: false,
  weeks: [],
  data: [],
};

const entries = (state = initialState, { type, payload }) => {
  console.log("entries", type, payload);
  switch (type) {
    case "getAppDataCommit":
    case "loginUserCommit":
      return {
        ...state,
        isLoading: false,
        isUpdating: false,
        week: payload.week,
        weeks: payload.weeks,
        data: payload.entries,
      };

    case "getEntriesInit":
      return {
        ...state,
        isLoading: true,
      };
    case "getEntriesFail":
      return {
        ...state,
        isLoading: false,
      };
    case "getEntriesCommit":
      return {
        ...state,
        isLoading: false,
        week: payload.data.week,
        data: payload.data.entries,
      };

    case "setEntryID":
      console.log("setEntryID", payload);
      return {
        ...state,
        entryID: payload,
      };

    case "createEntryInit":
      return {
        ...state,
        isCreating: true,
      };

    case "createEntryFail":
      return {
        ...state,
        isCreating: false,
      };

    case "createEntryCommit":
      let newArray = state.data.slice();
      newArray.splice(0, 0, payload.data);

      return {
        ...state,
        entryID: payload.id,
        data: newArray,
        weeks: state.weeks.map((item) => {
          if (item.start === payload.weekStats.start) {
            return {
              ...item,
              ...payload.weekStats,
            };
          }
          return item;
        }),
        isCreating: false,
      };

    case "updateEntryInit":
      return {
        ...state,
        isUpdating: true,
      };

    case "updateEntryFail":
      return {
        ...state,
        isUpdating: false,
      };

    case "updateEntryCommit":
      if(!Array.isArray(state.data)) {
        return {...state,isUpdating: false}
      }
      return {
        ...state,
        data: state.data.map((item) => {
          if (item.id === payload.id) {
            console.log("updated", {
              ...item,
              ...payload.data,
            });
            return {
              ...item,
              ...payload.data,
            };
          }
          return item;
        }),
        weeks: payload.weekStats
          ? state.weeks.map((item) => {
              if (item.start === payload.weekStats.start) {
                return {
                  ...item,
                  ...payload.weekStats,
                };
              }
              return item;
            })
          : state.weeks,
        isUpdating: false,
      };

    case "deleteEntryInit":
      return {
        ...state,
        isDeleting: true,
      };

    case "deleteEntryFail":
      return {
        ...state,
        isDeleting: false,
      };

    case "deleteEntryCommit":
      if(!Array.isArray(state.data)) {
        return {...state,isUpdating: false}
      }
      return {
        ...state,
        data: state.data.filter((entry) => {
          return entry.id !== payload.id;
        }),
        weeks: state.weeks.map((item) => {
          if (item.start === payload.weekStats.start) {
            return {
              ...item,
              ...payload.weekStats,
            };
          }
          return item;
        }),
        isDeleting: false,
      };

    case "createWorkInit":
      return {
        ...state,
        isUpdating: true,
      };

    case "createWorkFail":
      return {
        ...state,
        isUpdating: false,
      };

    case "createWorkCommit":
      if(!Array.isArray(state.data)) {
        return {...state,isUpdating: false}
      }
      return {
        ...state,
        data: state.data.map((entry) => {
          if (entry.id === payload.entry_id) {
            let newArray = entry.work.slice();
            newArray.splice(0, 0, payload.data);
            entry.work = newArray;
          }
          return entry;
        }),
        isUpdating: false,
      };

    case "updateWorkInit":
      return {
        ...state,
        isUpdating: true,
      };

    case "updateWorkFail":
      return {
        ...state,
        isUpdating: false,
      };

    case "updateWorkCommit":
      if(!Array.isArray(state.data)) {
        return {...state,isUpdating: false}
      }
      return {
        ...state,
        data: state.data.map((entry) => {
          if (entry.id === payload.entry_id) {
            entry.work = entry.work.map((work) => {
              if (work.id === payload.data.id) {
                return payload.data;
              }
              return work;
            });
          }
          return entry;
        }),
        isUpdating: false,
      };

    case "deleteWorkInit":
      return {
        ...state,
        isUpdating: true,
      };

    case "deleteWorkFail":
      return {
        ...state,
        isUpdating: false,
      };

    case "deleteWorkCommit":
      if(!Array.isArray(state.data)) {
        return {...state,isUpdating: false}
      }
      return {
        ...state,
        data: state.data.map((entry) => {
          if (entry.id === payload.entry_id) {
            entry.work = entry.work.filter((work) => {
              return work.id !== payload.id;
            });
          }
          return entry;
        }),
        isUpdating: false,
      };

    case "fetchWeeksInit":
      return {
        ...state,
        isLoading: true,
      };

    case "fetchWeeksFail":
      return {
        ...state,
        isLoading: false,
      };

    case "fetchWeeksCommit":
      return {
        ...state,
        weeks: [...state.weeks, ...payload.weekStats],
        isLoading: false,
      };

    default:
      return state;
  }
};
export default entries;
