import React, { Component } from "react";
import { connect } from "react-redux";

import { Formik, Form, Field } from "formik";

import Dialog from "../../ui/Dialog";

import Button from "../../ui/Button";
import Divider from "../../ui/Divider";

import { STATUS } from "../../constants";

class StatusPicker extends Component {
  render() {
    const {
      isShown,
      onCloseComplete,
      updateStatus,
      selected,
      disableButtons,
      multiple,
      isRequired,
    } = this.props;
    return (
      <Dialog
        title={"Select Status"}
        isShown={isShown}
        onCloseComplete={onCloseComplete}
        disableButtons={disableButtons}
        hideFooter
        narrow
      >
        {({ close }) => (
          <Formik
            initialValues={{
              status: multiple
                ? selected
                  ? selected
                  : []
                : selected
                ? selected
                : "",
            }}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={(values) => {
              updateStatus(values, close);
            }}
          >
            {({ errors, touched, isSubmitting, values }) => (
              <Form className="w-full relative flex flex-col items-stretch">
                <Divider />
                <div className="relative w-full h-full flex flex-col items-stretch overflow-auto">
                  {!isRequired && (
                    <label className="p-2 border border-gray-200 rounded mb-1">
                      <Field
                        name="status"
                        type="radio"
                        value=""
                        className="mr-2"
                      />
                      All Statuses
                    </label>
                  )}

                  <label className="p-2 border border-gray-200 rounded mb-1">
                    <Field
                      name="status"
                      type={multiple ? "checkbox" : "radio"}
                      value={"approved"}
                      className="mr-2"
                    />
                    {STATUS["approved"]}
                  </label>

                  <label className="p-2 border border-gray-200 rounded mb-1">
                    <Field
                      name="status"
                      type={multiple ? "checkbox" : "radio"}
                      value={"declined"}
                      className="mr-2"
                    />
                    {STATUS["declined"]}
                  </label>

                  <label className="p-2 border border-gray-200 rounded mb-1">
                    <Field
                      name="status"
                      type={multiple ? "checkbox" : "radio"}
                      value={"paid"}
                      className="mr-2"
                    />
                    {STATUS["paid"]}
                  </label>

                  <label className="p-2 border border-gray-200 rounded mb-1">
                    <Field
                      name="status"
                      type={multiple ? "checkbox" : "radio"}
                      value={"pending"}
                      className="mr-2"
                    />
                    {STATUS["pending"]}
                  </label>
                </div>

                <Divider />
                <div className="">
                  <Button fullWidth appearance="dark" type="submit">
                    Update Statuses
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </Dialog>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    user: store.appState.user,
  };
};

export default connect(mapStateToProps, null)(StatusPicker);
