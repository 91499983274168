import React from "react";

const Logo = ({ type, width = "auto", height = "auto" }) => {
  if (type === "northern") {
    return (
      <img
        src="/logo-northern-white.png"
        alt="D&B Scaffolding (Northern) Ltd."
        style={{ width: width, height: height }}
      />
    );
  }
  return (
    <img
      src="/logo-white.png"
      alt="D&B Scaffolding Ltd."
      style={{ width: width, height: height }}
    />
  );
};

export default Logo;
