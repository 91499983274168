import React, { Component } from "react";
import { DateTime } from "luxon";
import { Formik, Form } from "formik";
import queryString from "query-string";

import Dialog from "../../ui/Dialog";

import { API_URL } from "../../constants";

import Label from "../../ui/Label";
import InputDatePicker from "../../ui/InputDatePicker";
import InputError from "../../ui/InputError";
import Button from "../../ui/Button";
import Divider from "../../ui/Divider";
import Paragraph from "../../ui/Paragraph";

import OperativePicker from "./OperativePicker";
import ManagerPicker from "./ManagerPicker";
import DevelopmentPicker from "./DevelopmentPicker";
import StatusPicker from "./StatusPicker";
import { STATUS } from "../../constants";

const startOfWeek = DateTime.utc().startOf("week");
const endOfWeek = DateTime.utc().endOf("week");

class ExportEntries extends Component {
  state = {
    filters: {
      from: startOfWeek.toFormat("yyyy-MM-dd"),
      to: endOfWeek.toFormat("yyyy-MM-dd"),
      status: ["approved"],
      developments: [],
      operatives: [],
      managers: [],
    },
    editOperative: false,
    editDevelopment: false,
    editManager: false,
    editStatus: false,
  };
  onCloseComplete = () => {
    const { onCloseComplete } = this.props;
    onCloseComplete();
  };
  render() {
    const { isShown, user } = this.props;

    return (
      <>
        <Dialog
          title={"Export Entries"}
          isShown={isShown}
          onCloseComplete={this.onCloseComplete}
          hideFooter
          narrow
        >
          {({ close }) => (
            <>
              <Formik
                enableReinitialize
                initialValues={this.state.filters}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={(values, actions) => {
                  close();
                  const params = queryString.stringify(
                    {
                      from: values.from,
                      to: values.to,
                      developments: values.developments.map((o) => o.id),
                      operatives: values.operatives.map((o) => o.id),
                      managers: values.managers.map((o) => o.id),
                      status: values.status,
                    },
                    {
                      arrayFormat: "comma",
                    }
                  );
                  window.location.replace(API_URL + "/export?" + params);
                }}
              >
                {({
                  errors,
                  touched,
                  dirty,
                  resetForm,
                  isSubmitting,
                  values,
                  setFieldValue,
                }) => (
                  <Form className="w-full relative flex flex-col items-stretch">
                    <Divider />
                    <div className="relative w-full h-full flex flex-col items-stretch overflow-auto px-1 -mx-1">
                      <div className="grid grid-cols-2 gap-2">
                        <div className="mb-3">
                          <Label>From</Label>
                          <InputDatePicker
                            setFieldValue={setFieldValue}
                            field="from"
                            value={values.from}
                            placeholder="Choose Date"
                            disableClose
                            hasError={errors.from}
                          />
                          <InputError>
                            {errors.date ? errors.date : null}
                          </InputError>
                        </div>
                        <div className="mb-3">
                          <Label>To</Label>
                          <InputDatePicker
                            setFieldValue={setFieldValue}
                            field="to"
                            value={values.to}
                            placeholder="Choose Date"
                            disableClose
                            hasError={errors.to}
                          />
                          <InputError>
                            {errors.date ? errors.date : null}
                          </InputError>
                        </div>
                      </div>

                      <div className="mb-3">
                        <Label>Status</Label>

                        <button
                          type="button"
                          onClick={() => this.setState({ editStatus: true })}
                          className="px-4 py-2 flex flex-row items-center justify-between text-sm flex-1 w-full border border-gray-100 bg-gray-50 rounded  hover:border-gray-300 focus:border-gray-300 focus:outline-none focus:shadow-outline"
                        >
                          <Paragraph className="flex-1 text-left">
                            {values.status && values.status.length > 0 ? (
                              values.status.map((status) => (
                                <span
                                  key={"status-" + status}
                                  className="block"
                                >
                                  {STATUS[status]}
                                </span>
                              ))
                            ) : (
                              <span className="text-gray-500">Not Set</span>
                            )}
                          </Paragraph>
                        </button>
                        <InputError>
                          {errors.status ? errors.status : null}
                        </InputError>
                      </div>

                      <div className="mb-3">
                        <Label>Site Operative</Label>

                        <button
                          type="button"
                          onClick={() => this.setState({ editOperative: true })}
                          className="px-4 py-2 flex flex-row items-center justify-between text-sm flex-1 w-full border border-gray-100 bg-gray-50 rounded  hover:border-gray-300 focus:border-gray-300 focus:outline-none focus:shadow-outline"
                        >
                          <Paragraph className="flex-1 text-left">
                            {values.operatives &&
                            values.operatives.length > 0 ? (
                              values.operatives.map((manager) => (
                                <span
                                  key={"manager-" + manager.id}
                                  className="block"
                                >
                                  {manager.display_name}
                                </span>
                              ))
                            ) : (
                              <span className="text-gray-500">Not Set</span>
                            )}
                          </Paragraph>
                        </button>
                        <InputError>
                          {errors.operatives ? errors.operatives : null}
                        </InputError>
                      </div>

                      {user && user.role !== "supervisor" && (
                        <div className="mb-3">
                          <Label>Assigned to Area Supervisor</Label>

                          <button
                            type="button"
                            onClick={() => this.setState({ editManager: true })}
                            className="px-4 py-2 flex flex-row items-center justify-between text-sm flex-1 w-full border border-gray-100 bg-gray-50 rounded  hover:border-gray-300 focus:border-gray-300 focus:outline-none focus:shadow-outline"
                          >
                            <Paragraph className="flex-1 text-left">
                              {values.managers && values.managers.length > 0 ? (
                                values.managers.map((manager) => (
                                  <span
                                    key={"manager-" + manager.id}
                                    className="block"
                                  >
                                    {manager.display_name}
                                  </span>
                                ))
                              ) : (
                                <span className="text-gray-500">Not Set</span>
                              )}
                            </Paragraph>
                          </button>
                          <InputError>
                            {errors.operatives ? errors.operatives : null}
                          </InputError>
                        </div>
                      )}

                      <div className="mb-3">
                        <Label>Site / Development</Label>

                        <button
                          type="button"
                          onClick={() =>
                            this.setState({ editDevelopment: true })
                          }
                          className="px-4 py-2 flex flex-row items-center justify-between text-sm flex-1 w-full border border-gray-100 bg-gray-50 rounded  hover:border-gray-300 focus:border-gray-300 focus:outline-none focus:shadow-outline"
                        >
                          <Paragraph className="flex-1 text-left">
                            {values.developments &&
                            values.developments.length > 0 ? (
                              values.developments.map((dev) => (
                                <span
                                  key={"development-" + dev.id}
                                  className="block"
                                >
                                  {dev.name}
                                </span>
                              ))
                            ) : (
                              <span className="text-gray-500">Not Set</span>
                            )}
                          </Paragraph>
                        </button>
                        <InputError>
                          {errors.development ? errors.development : null}
                        </InputError>
                      </div>
                    </div>

                    <Divider />

                    <Button fullWidth appearance="dark" type="submit">
                      Export Timesheets
                    </Button>

                    <OperativePicker
                      isShown={this.state.editOperative}
                      selected={values.operatives.map((item) => "ID" + item.id)}
                      multiple={true}
                      updateOperative={(values, operatives, close) => {
                        setFieldValue("operatives", operatives);
                        close();
                      }}
                      onCloseComplete={() =>
                        this.setState({
                          editOperative: false,
                        })
                      }
                    />

                    <ManagerPicker
                      isShown={this.state.editManager}
                      selected={values.managers.map((item) => "ID" + item.id)}
                      multiple={true}
                      updateManager={(values, managers, close) => {
                        setFieldValue("managers", managers);
                        close();
                      }}
                      onCloseComplete={() =>
                        this.setState({
                          editManager: false,
                        })
                      }
                    />
                    <DevelopmentPicker
                      isShown={this.state.editDevelopment}
                      selected={values.developments}
                      multiple={true}
                      updateDevelopment={(values, developments, close) => {
                        setFieldValue("developments", developments);
                        close();
                      }}
                      onCloseComplete={() =>
                        this.setState({
                          editDevelopment: false,
                        })
                      }
                    />
                    <StatusPicker
                      isShown={this.state.editStatus}
                      selected={values.status}
                      multiple={true}
                      updateStatus={(values, close) => {
                        setFieldValue("status", values.status);
                        close();
                      }}
                      onCloseComplete={() =>
                        this.setState({
                          editStatus: false,
                        })
                      }
                    />
                  </Form>
                )}
              </Formik>
            </>
          )}
        </Dialog>
      </>
    );
  }
}

export default ExportEntries;
