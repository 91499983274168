import React from "react";

const RotateDevice = () => {
  return (
    <div className="fixed inset-0 z-150 bg-white flex flex-col w-full h-full items-center justify-center">
      <svg
        className="text-red-500 mb-8 w-40"
        viewBox="0 0 640 640"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="currentColor" fillRule="evenodd">
          <g transform="translate(24 71)">
            <path
              d="M270 0H28C12.536 0 0 12.536 0 28v429c0 15.464 12.536 28 28 28h242c15.464 0 28-12.536 28-28V28c0-15.464-12.536-28-28-28zM28 16h242c6.627 0 12 5.373 12 12v429c0 6.627-5.373 12-12 12H28c-6.627 0-12-5.373-12-12V28c0-6.627 5.373-12 12-12z"
              fillRule="nonzero"
            />
            <rect x="79" y="448" width="140" height="9" rx="4.5" />
            <rect x="79" y="1" width="140" height="30" rx="8" />
          </g>
          <g transform="rotate(90 193 423.5)">
            <path
              d="M268 0H28C12.536 0 0 12.536 0 28v282h296V28c0-15.464-12.536-28-28-28zm0 16l.305.004C274.791 16.166 280 21.474 280 28v266H15.999V28c0-6.627 5.374-12 12.001-12h240z"
              fillRule="nonzero"
            />
            <rect x="79" y="1" width="140" height="30" rx="8" />
          </g>
          <path fill-opacity=".15" fill="#000" d="M322 231h14v296h-14z" />
          <path
            d="M223.24 264.988l-74.212 73.96-27.269-27.175a3.394 3.394 0 00-4.788 0l-7.98 7.953a3.366 3.366 0 000 4.772l37.643 37.514a3.394 3.394 0 004.788 0l84.586-84.3a3.366 3.366 0 000-4.771l-7.98-7.953a3.394 3.394 0 00-4.787 0zM452.11 153.845h-55.86v19.53c0 3.243-3.946 4.903-6.264 2.59l-26.911-26.856a3.657 3.657 0 010-5.18l26.91-26.853c2.305-2.3 6.265-.67 6.265 2.59v19.53h55.79c25.664 0 46.841 20.557 46.96 46.169.032 7.079-14.686 7.079-14.678.251.018-17.475-14.7-31.771-32.211-31.771z"
            fillRule="nonzero"
          />
        </g>
      </svg>

      <h2 className="text-gray-900 mb-2 font-bold text-xl">
        Please Rotate your Device.
      </h2>
      <p>This app works best in portrait.</p>
    </div>
  );
};

export default RotateDevice;
