import React, { Component } from "react";
import { connect } from "react-redux";

import { Formik, Form, Field } from "formik";

import Dialog from "../../ui/Dialog";
import Loading from "../../ui/Loading";

import Button from "../../ui/Button";
import Divider from "../../ui/Divider";

import { getDevelopments } from "../../actions/developments";

class DevelopmentPicker extends Component {
  render() {
    const {
      isShown,
      user,
      onCloseComplete,
      updateDevelopment,
      developments,
      selected,
      disableButtons,
      disableFetch,
      multiple,
      managedBy,
      isRequired,
    } = this.props;
    return (
      <Dialog
        title={"Select Development"}
        isShown={isShown}
        onCloseComplete={onCloseComplete}
        onOpen={() => {
          // fetch all developments from server
          if (!disableFetch) {
            this.props.getDevelopments();
          }
        }}
        disableButtons={disableButtons}
        hideFooter
        fullHeight
        narrow
      >
        {({ close }) => (
          <Formik
            initialValues={{
              development: multiple
                ? selected
                  ? selected
                  : []
                : selected
                ? "ID" + selected
                : "",
            }}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={(values) => {
              updateDevelopment(
                values,
                multiple
                  ? developments.data.filter((development) =>
                      values.development.includes("ID" + development.id)
                    )
                  : developments.data.find(
                      (development) =>
                        values.development === "ID" + development.id
                    ),
                close
              );
            }}
          >
            {({ errors, touched, isSubmitting, values }) => (
              <Form className="w-full relative flex flex-col items-stretch">
                <Divider />
                {developments.isLoading && (
                  <div className="z-10 absolute inset-0 bg-white bg-opacity-75">
                    <Loading />
                  </div>
                )}
                <div className="relative w-full h-full flex flex-col items-stretch overflow-auto">
                  {!isRequired && (
                    <label className="p-2 border border-gray-200 rounded mb-1">
                      <Field
                        name="development"
                        type="radio"
                        value=""
                        className="mr-2"
                      />
                      No Development
                    </label>
                  )}
                  {isRequired &&
                    !developments.isLoading &&
                    developments.data.filter((development) => {
                      if (!managedBy) {
                        return development;
                      } else {
                        return development.manager_id === managedBy;
                      }
                    }).length <= 0 && (
                      <div className="p-2 border border-gray-200 rounded mb-1">
                        No Developments Found
                      </div>
                    )}
                  {developments.data
                    .filter((development) => {
                      if (!managedBy) {
                        return development;
                      } else {
                        return development.manager_id === managedBy;
                      }
                    })
                    .map((development) => {
                      return (
                        <label
                          key={development.id}
                          className="p-2 border border-gray-200 rounded mb-1"
                        >
                          <Field
                            name="development"
                            type={multiple ? "checkbox" : "radio"}
                            value={"ID" + development.id}
                            className="mr-2"
                          />
                          {development.name}
                        </label>
                      );
                    })}
                </div>

                <Divider />
                <div className="">
                  {user && user.isUpdating && (
                    <Button fullWidth appearance="dark" disabled type="button">
                      Updating...
                    </Button>
                  )}

                  {(!user || !user.isUpdating) && (
                    <Button fullWidth appearance="dark" type="submit">
                      Update Development
                    </Button>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        )}
      </Dialog>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    user: store.appState.user,
    developments: store.developments,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getDevelopments: (payload, meta) => dispatch(getDevelopments(payload, meta)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DevelopmentPicker);
