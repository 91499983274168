import React, { Component } from "react";
import { CSSTransition } from "react-transition-group";
import PropTypes from "prop-types";

import Overlay from "./Overlay";
import ButtonClose from "./ButtonClose";
import Button from "./Button";
import Popover from "./Popover";

const ANIMATION_DURATION = 200;

class Dialog extends Component {
  static propTypes = {
    /**
     * The display property passed to the Popover card.
     */
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,

    /**
     * The target button of the Popover.
     * When a function the following arguments are passed:
     * ({ toggle: Function -> Void, getRef: Function -> Ref, isShown: Bool })
     */
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.element,
      PropTypes.func,
    ]).isRequired,

    contextMenu: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.element,
      PropTypes.func,
    ]),

    /**
     * When true, the dialog is shown.
     */
    isShown: PropTypes.bool,

    /**
     * Function that will be called when the exit transition is complete.
     */
    onCloseComplete: PropTypes.func,
    onExited: PropTypes.func,

    /**
     * Function that will be called when the enter transition is complete.
     */
    onOpenComplete: PropTypes.func,
    onOpen: PropTypes.func,

    /**
     * Function that will be called when the confirm button is clicked.
     * This does not close the Dialog. A close function will be passed
     * as a paramater you can use to close the dialog.
     *
     * `onConfirm={(close) => close()}`
     */
    onConfirm: PropTypes.func,

    /**
     * Function that will be called when the cancel button is clicked.
     * This closes the Dialog by default.
     *
     * `onCancel={(close) => close()}`
     */
    onCancel: PropTypes.func,
    onDiscard: PropTypes.func,
    onSecondary: PropTypes.func,

    /**
     * Boolean indicating if clicking the overlay should close the overlay.
     */
    shouldCloseOnOverlayClick: PropTypes.bool,

    /**
     * Boolean indicating if pressing the esc key should close the overlay.
     */
    shouldCloseOnEscapePress: PropTypes.bool,

    /**
     * Whether or not to prevent scrolling in the outer body
     */
    preventBodyScrolling: PropTypes.bool,

    wide: PropTypes.bool,
    xWide: PropTypes.bool,
    noPadding: PropTypes.bool,
    narrow: PropTypes.bool,
    hideFooter: PropTypes.bool,
    hideHeader: PropTypes.bool,
    hasDiscardButton: PropTypes.bool,
    hasSecondaryButton: PropTypes.bool,
    fullHeight: PropTypes.bool,
    disableConfirm: PropTypes.bool,

    hideClose: PropTypes.bool,
    disableButtons: PropTypes.bool,
    hideCancel: PropTypes.bool,
    hideConfirm: PropTypes.bool,
    hideWrapper: PropTypes.bool,
    isWarning: PropTypes.bool,

    cancelText: PropTypes.string,
    confirmText: PropTypes.string,
    discardText: PropTypes.string,
    secondaryText: PropTypes.string,
  };

  static defaultProps = {
    isShown: false,
    wide: false,
    xWide: false,
    noPadding: false,
    narrow: false,
    hideFooter: false,
    hideHeader: false,
    hasDiscardButton: false,
    hasSecondaryButton: false,
    shouldCloseOnOverlayClick: false,
    shouldCloseOnEscapePress: true,
    onCancel: (close) => close(),
    onConfirm: (close) => close(),
    onDiscard: (close) => close(),
    onSecondary: (close) => close(),
    onExited: () => {},
    hideClose: false,
    disableButtons: false,
    hideCancel: false,
    hideConfirm: false,
    preventBodyScrolling: true,
    fullHeight: false,
    fullWidth: false,
    hideWrapper: false,
    isWarning: false,
    disableConfirm: false,
  };

  constructor(props) {
    super(props);
    this.transitionRef = React.createRef();
  }

  componentDidMount() {
    console.log("Dialog componentDidMount");
  }
  componentWillUnmount() {
    console.log("Dialog componentWillUnmount");
  }

  render() {
    const {
      isShown,
      onCloseComplete,
      onExited,
      onOpenComplete,
      onOpen,
      shouldCloseOnOverlayClick,
      shouldCloseOnEscapePress,
      preventBodyScrolling,
      wide,
      xWide,
      narrow,
      title,
      children,
      onCancel,
      onConfirm,
      hideFooter,
      hideClose,
      disableButtons,
      hideCancel,
      hideConfirm,
      cancelText,
      confirmText,
      hasDiscardButton,
      onDiscard,
      discardText,
      hasSecondaryButton,
      onSecondary,
      secondaryText,
      fullHeight,
      fullWidth,
      hideWrapper,
      noPadding,
      disableConfirm,
      contextMenu,
      isWarning,
    } = this.props;

    const t = (string) => {
      return string;
    };

    return (
      <Overlay
        isShown={isShown}
        shouldCloseOnClick={shouldCloseOnOverlayClick}
        shouldCloseOnEscapePress={shouldCloseOnEscapePress}
        onExit={onCloseComplete}
        onEnter={onOpen}
        onEntered={onOpenComplete}
        onExited={() => {
          console.log("onExited");
          onExited();
        }}
        preventBodyScrolling={preventBodyScrolling}
      >
        {({ close }) => (
          <CSSTransition
            nodeRef={this.transitionRef}
            appear
            unmountOnExit
            timeout={ANIMATION_DURATION}
            in={isShown}
            classNames="dialog"

            // onExit={this.handleExit}
            // onExiting={this.handleExiting}
            // onExited={this.handleExited}
            // onEnter={this.handleEnter}
            // onEntering={this.handleEntering}
            // onEntered={this.handleEntered}
          >
            <div
              ref={this.transitionRef}
              className={
                "dialog z-100 flex flex-col bg-white rounded-2xl shadow-2xl " +
                (noPadding ? " " : "p-6 ") +
                (fullWidth
                  ? "dialog-full "
                  : xWide
                  ? "dialog-xwide "
                  : wide
                  ? "dialog-wide "
                  : narrow
                  ? "dialog-narrow "
                  : " ") +
                (fullHeight ? "dialog-tall " : " ")
              }
            >
              {title !== "" && (
                <div className="flex flex-row items-center pb-3">
                  <h1 className="flex-1 antialiased text-gray-900 text-xl font-semibold mr-4">
                    {title}
                  </h1>
                  {contextMenu && (
                    <Popover
                      placement="bottom-end"
                      btnContent={(ref, toggle) => (
                        <div ref={ref}>
                          <ButtonClose
                            margin={hideClose ? false : "mr-1"}
                            type="button"
                            disabled={disableButtons}
                            onClick={toggle}
                            icon="ellipsis-v"
                            width="w-8"
                          ></ButtonClose>
                        </div>
                      )}
                      content={({ close }) => (
                        <div
                          className={
                            "relative m-1 p-1 rounded-sm bg-white border-gray-200 border shadow-xl flex flex-col text-left w-48"
                          }
                        >
                          {typeof contextMenu === "function"
                            ? contextMenu({
                                close: () => onCancel(close),
                              })
                            : contextMenu}
                        </div>
                      )}
                    />
                  )}

                  {!hideClose && (
                    <ButtonClose
                      type="button"
                      disabled={disableButtons}
                      onClick={() => onCancel(close)}
                    ></ButtonClose>
                  )}
                </div>
              )}

              {!hideWrapper ? (
                <div
                  className={
                    "flex overflow-auto min-h-0 h-full -mx-1 p-1 bg-white text-gray-900 "
                  }
                >
                  {typeof children === "function"
                    ? children({
                        close: () => onCancel(close),
                      })
                    : children}
                </div>
              ) : typeof children === "function" ? (
                children({ close: () => onCancel(close) })
              ) : (
                children
              )}

              {!hideFooter && (
                <div className="flex flex-col-reverse md:flex-row items-center justify-between pt-5 -mx-1">
                  {!hideCancel && (
                    <Button
                      appearance="light"
                      type="button"
                      disabled={disableButtons}
                      margin="w-full md:w-auto"
                      onClick={() => onCancel(close)}
                    >
                      {cancelText ? cancelText : t("cancel")}
                    </Button>
                  )}
                  <div className="flex flex-col-reverse md:flex-row items-center w-full md:w-auto mb-1 md:mb-0">
                    {hasDiscardButton && (
                      <Button
                        appearance="light"
                        margin="w-full md:w-auto md:mr-1"
                        type="button"
                        disabled={disableButtons}
                        onClick={() => onDiscard(close)}
                      >
                        {discardText ? discardText : t("discard")}
                      </Button>
                    )}
                    {hasSecondaryButton && (
                      <Button
                        tabIndex={0}
                        appearance="dark"
                        margin="w-full md:w-auto md:mr-1"
                        type="button"
                        disabled={disableButtons}
                        onClick={() => onSecondary(close)}
                      >
                        {secondaryText ? secondaryText : t("action")}
                      </Button>
                    )}
                    {!hideConfirm && (
                      <Button
                        tabIndex={0}
                        appearance={isWarning ? "red" : "dark"}
                        type="button"
                        margin="w-full md:w-auto"
                        onClick={() => onConfirm(close)}
                        disabled={disableConfirm || disableButtons}
                      >
                        {confirmText ? confirmText : t("confirm")}
                      </Button>
                    )}
                  </div>
                </div>
              )}
            </div>
          </CSSTransition>
        )}
      </Overlay>
    );
  }
}

export default Dialog;
