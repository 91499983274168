import { combineReducers } from "redux";

import appState from "./appState";
import managers from "./managers";
import operatives from "./operatives";
import developments from "./developments";
import entries from "./entries";
import approvals from "./approvals";
import documents from "./documents";
import users from "./users";
import ratecard from "./ratecard";
import ratecards from "./ratecards";
import documentUsers from "./documentUsers";
import yard from "./yard";

export default combineReducers({
  appState,
  managers,
  operatives,
  developments,
  entries,
  approvals,
  documents,
  users,
  ratecard,
  ratecards,
  documentUsers,
  yard,
});
